import clsx from "clsx";
import { findAll } from "highlight-words-core";
import Highlighter from "react-highlight-words";
import { stemmer } from "stemmer";
import styles from "./HighlightWords.module.css";
import { sanitizeText, wordsMatchingTerms } from "./utils";

interface HighlightTagProps {
  children: string;
}

const HIGHLIGHT_COLORS = [
  styles.GreenHighlight,
  styles.YellowHighlight,
  styles.BlueHighlight,
  styles.IndigoHighlight,
  styles.OrangeHighlight,
  styles.PurpleHighlight,
  styles.PinkHighlight,
  styles.TealHighlight,
];

const highlighterForText = (
  text: string,
  searchTerms: string[],
  isDarkModeOn: boolean,
  whiteSpace: "pre-line" | "normal" = "pre-line",
): JSX.Element => {
  const stemmedSearchTerms = searchTerms.map(stemmer);

  const HighlightTag = ({ children }: HighlightTagProps): JSX.Element => {
    const i = stemmedSearchTerms.indexOf(stemmer(children.toLowerCase()));
    return (
      <span
        className={clsx(HIGHLIGHT_COLORS[i % HIGHLIGHT_COLORS.length], {
          [styles.dark]: isDarkModeOn,
        })}
      >
        {children}
      </span>
    );
  };

  return (
    <Highlighter
      className={clsx(whiteSpace === "pre-line" && [styles.Highlighter])}
      searchWords={wordsMatchingTerms(sanitizeText(text), searchTerms)}
      autoEscape={true}
      highlightTag={HighlightTag}
      textToHighlight={text}
    />
  );
};

export const highlighterForHtml = (
  html: string,
  searchTerms: string[],
): string => {
  const chunks = findAll({
    textToHighlight: html,
    searchWords: wordsMatchingTerms(html, searchTerms),
  });

  return chunks
    .map((chunk) => {
      const { end, highlight, start } = chunk;
      const text = html.substring(start, end);

      if (highlight && !textIsWithinHtmlTag({ start, end }, html)) {
        // only supports one match and doesn't support dark mode so we can just make this green
        return `<mark class=${styles.GreenHighlight}>${text}</mark>`;
      } else {
        return text;
      }
    })
    .join("");
};

export default highlighterForText;

export const textIsWithinHtmlTag = (
  match: { start: number; end: number },
  html: string,
): boolean => {
  //Returns a boolean indicating if the match text is within an html tag
  // e.g. start=2, end=4 html=<span>hello</span> should return true
  // and start=12, end=14 html=<span>hello pa</span> should return false

  // Use regular expression to find HTML tags in the input HTML string
  const tagRegex = /<[^>]*>/gi;
  const tags = html.matchAll(tagRegex);

  // If there are no HTML tags, the match text cannot be within a tag
  if (!tags) {
    return false;
  }

  // Iterate through the found HTML tags
  for (const tag of tags) {
    // Check if the match text is within the range of the current HTML tag
    if (
      tag.index !== undefined &&
      match.end <= tag.index + tag[0].length &&
      match.start >= tag.index
    ) {
      return true;
    }
  }

  // If no match is found within any tag, return false
  return false;
};
