import { skipToken } from "@reduxjs/toolkit/query";
import { Alert, Flex, Select, Space, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RcFile } from "antd/lib/upload";
import { useGetQuestionnaireAnalyticsQuery } from "../../../../src/features/API/Dashboard/dashboard";
import ActiveProductSelector from "../../../components/ActiveProductSelector";
import AppHeader from "../../../components/AppHeader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import UploadDragger from "../../../components/UploadDragger";
import { useBulkUploadPendingQuestionsMutation } from "../../../features/API/pendingQuestions";
import { QuestionnaireTaskId } from "../../../features/MissionControl/types/tasks";
import { getErrorDetail, useActiveProduct } from "../../../utils/hooks";

import styles from "./BulkUploadQuestions.module.scss";

const BulkUploadQuestions = () => {
  const navigate = useNavigate();
  const activeProduct = useActiveProduct();
  const [pendingQuestionsCSV, setPendingQuestionsCSV] = useState<RcFile>();
  const [taskId, setTaskId] = useState<QuestionnaireTaskId>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [bulkUpload] = useBulkUploadPendingQuestionsMutation();

  const { data } = useGetQuestionnaireAnalyticsQuery(
    activeProduct
      ? {
          companyId: activeProduct.relationships.company.data.id,
          filters: {
            statuses: [
              "undergoingCompletion",
              "qaCheck",
              "completedAwaitingReturn",
            ],
          },
        }
      : skipToken,
  );

  const uploadPendingQuestions = async () => {
    if (!pendingQuestionsCSV) {
      message.error("No CSV selected.");
      return;
    }
    if (!activeProduct) {
      message.error("No product selected.");
      return;
    }

    const formData = new FormData();
    formData.append("questionnaireTaskId", taskId as string);
    formData.append("file", pendingQuestionsCSV);

    const response = await bulkUpload(formData);
    if ("error" in response) {
      const errorDetail = getErrorDetail(response.error);
      message.error(`Error uploading pending questions: ${errorDetail}`);
      return;
    }

    message.success("Upload complete!");
    setShowConfirmationModal(false);
    setTaskId(undefined);
    setPendingQuestionsCSV(undefined);
  };

  return (
    <>
      <Flex justify="space-between">
        <AppHeader
          title="Bulk Upload"
          onBack={() => {
            navigate("/pending-questions-internal");
          }}
        />

        <Flex gap={"small"}>
          <div>
            Library: <ActiveProductSelector />
          </div>
          <div>
            Questionnaire Task:{" "}
            <Select
              className={styles.SelectQuestionnaireTaskInput}
              showSearch
              size="middle"
              placeholder="Search task"
              value={taskId}
              optionFilterProp="label"
              onChange={(taskId: QuestionnaireTaskId) => {
                setTaskId(taskId);
              }}
              options={(data?.data.questionnaires ?? []).map((q) => ({
                label: q.prospectName,
                value: q.id,
              }))}
            />
          </div>
        </Flex>
      </Flex>
      <Flex vertical gap="middle">
        <Alert
          message="Instructions"
          type="info"
          showIcon
          description={
            <>
              <p>
                Select the library and questionnaire task you want to create
                pending questions for.
              </p>
              <p>
                By default you will only see the questionnaire tasks that are
                either <i>In Progress</i>, <i>Quality Check</i> or{" "}
                <i>Returned</i>.
              </p>
              <p>Finally, upload the CSV file.</p>
              <p>
                <b>Note:</b> A valid CSV file must have columns named
                'Question', 'Context' and 'Category'. Only these three columns
                will be imported.
              </p>
            </>
          }
        />

        <Space direction="vertical">
          <UploadDragger
            onSetFile={(file) => setPendingQuestionsCSV(file)}
            acceptedFiles=".csv"
            mainText="Click or drag file to this area to upload pending questions CSV."
            onButtonClick={() => {
              if (!activeProduct) {
                message.error("No product selected.");
                return;
              }
              setShowConfirmationModal(true);
            }}
            buttonDisabled={!pendingQuestionsCSV}
          />
        </Space>
      </Flex>
      {activeProduct && (
        <ConfirmationModal
          open={showConfirmationModal}
          title="Bulk Upload Pending Questions"
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={uploadPendingQuestions}
          confirmationValue={activeProduct.attributes.productName}
        >
          <p>
            Please type <code>{activeProduct.attributes.productName}</code> to
            confirm that you are uploading data for the correct product.
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default BulkUploadQuestions;
