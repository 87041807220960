import { skipToken } from "@reduxjs/toolkit/query";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetQuestionnaireAnalyticsQuery } from "../../features/API/Dashboard/dashboard";
import {
  CompanyId,
  DashboardQuestionnaireStatus,
  ProductId,
} from "../../features/API/types";
import { QuestionnaireTaskId } from "../../features/MissionControl/types/tasks";
import {
  DEBOUNCE_TIME_MILLISECONDS,
  DEFAULT_PAGE_SIZE,
} from "../../utils/constants";

interface QuestionnaireTaskOption {
  products?: ProductId[];
  value: QuestionnaireTaskId;
  label: string;
}

interface QuestionnaireTaskSelectorProps {
  onChange?: (taskId: QuestionnaireTaskId) => void;
  companyId?: CompanyId;
  disabled?: boolean;
  defaultValue?: QuestionnaireTaskId;
  placeholder?: string;
  statuses?: DashboardQuestionnaireStatus[];
}

const QuestionnaireTaskSelector = ({
  onChange,
  companyId,
  defaultValue,
  disabled = false,
  placeholder = "Select a Questionnaire",
  statuses,
}: QuestionnaireTaskSelectorProps): JSX.Element => {
  const [userName, setUserName] = useState("");
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] =
    useState<QuestionnaireTaskId>();

  const { data, isFetching: isFetchingQuestionnaires } =
    useGetQuestionnaireAnalyticsQuery(
      companyId
        ? {
            companyId,
            limit: DEFAULT_PAGE_SIZE,
            filters: {
              query: userName,
              statuses: statuses,
            },
          }
        : skipToken,
    );

  const questionnaires = useMemo(() => data?.data.questionnaires ?? [], [data]);

  // If questionnaires changes, verify if all the selected Questionnaire Id's are still valid
  useEffect(() => {
    if (
      !isFetchingQuestionnaires &&
      !questionnaires.find(
        (questionnaire) => questionnaire.id === selectedQuestionnaireId,
      )
    ) {
      setSelectedQuestionnaireId(undefined);
    }
  }, [isFetchingQuestionnaires, questionnaires, selectedQuestionnaireId]);

  const debouncedSearch = useMemo(
    () =>
      debounce((name: string) => {
        setUserName(name);
      }, DEBOUNCE_TIME_MILLISECONDS),
    [],
  );

  return (
    <Select<QuestionnaireTaskId, QuestionnaireTaskOption>
      showSearch
      aria-label="Questionnaire selector"
      loading={isFetchingQuestionnaires}
      placeholder={placeholder}
      disabled={disabled}
      onSearch={debouncedSearch}
      options={questionnaires.map((questionnaire) => ({
        value: questionnaire.id as QuestionnaireTaskId,
        label: questionnaire.prospectName,
      }))}
      notFoundContent={
        isFetchingQuestionnaires ? <Spin size="small" /> : undefined
      }
      filterOption={(input, option) =>
        !!option?.label?.toLowerCase().includes(input.toLowerCase())
      }
      onSelect={(value: QuestionnaireTaskId) => {
        setSelectedQuestionnaireId(value);
        onChange?.(value);
      }}
      value={selectedQuestionnaireId}
      defaultValue={defaultValue}
    />
  );
};

export default QuestionnaireTaskSelector;
