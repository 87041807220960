import {
  CloudUploadOutlined,
  ContactsOutlined,
  DiffOutlined,
  EditOutlined,
  FileTextOutlined,
  HeartOutlined,
  MailOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Result, theme } from "antd";
import clsx from "clsx";
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { BsBook } from "react-icons/bs";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { IoSparklesSharp } from "react-icons/io5";
import { LuHome } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import { CenteredSpinner } from "../../../../shared_frontend/src/Spinner";
import AppRoutes from "../../components/AppRoutes";
import { useGetProductsQuery } from "../../features/API";
import { useGetMeQuery } from "../../features/API/auth";
import { Permission } from "../../features/API/permissions";
import { hasPermission } from "../../features/API/types";
import { LAYOUT_KEY } from "../../features/Layout";
import { USER_AUTH_KEY } from "../../features/UserInfo";
import { UserAuthDataPartial } from "../../features/UserInfo/types";
import { clearLoggedInState } from "../../store/clearLoggedInState";
import BroadcastChannelManager from "../../utils/broadcastChannelManager";
import { LoginPagePath } from "../../utils/constants";
import { useAppSelector, useSetupTracking } from "../../utils/hooks";
import { useInitializeGeneralSocket } from "../../utils/webSockets/socket";
import {
  SideMenuItem,
  shouldShowSideMenuItem,
} from "../LayoutContainer/sideMenuItem";
import PendingQuestionsCustomerContainer from "../PendingQuestionsCustomerContainer";
import PendingQuestionsInternalContainer from "../PendingQuestionsInternalContainer";
import ReferralContainer from "../ReferralContainer";
import styles from "./App.module.scss";
import { BellIcon } from "./icons";

const DocsContainer = lazy(() => import("../DocsContainer"));
const AdminPanelContainer = lazy(() => import("../AdminPanel"));
const AnnouncementsContainer = lazy(() => import("../AnnouncementsContainer"));
const AnswerRequestContainer = lazy(() => import("../AnswerRequestContainer"));
const ProfileVersionPreview = lazy(
  () => import("../AssuranceProfileEditor/ProfileVersionPreview"),
);
const CopilotContainer = lazy(() => import("../CopilotContainer"));
const DataRoomContainer = lazy(() => import("../DataRoomContainer"));
const EnrollMFAContainer = lazy(() => import("../EnrollMFAContainer"));
const LoginContainer = lazy(() => import("../LoginContainer"));
const NavBar = lazy(() => import("../NavBar"));
const RegisterContainer = lazy(() => import("../RegisterContainer"));
const ResetPassword = lazy(() => import("../ResetPassword"));
const SettingsContainer = lazy(() => import("../SettingsContainer"));
const ValidateMFAContainer = lazy(() => import("../ValidateMFAContainer"));
const PrivateDataRoomFlowContainer = lazy(
  () => import("../PrivateDataRoomFlowContainer"),
);
const Layout = lazy(() => import("../LayoutContainer"));

const CompaniesContainer = lazy(
  () => import("../AdminPanel/CompaniesContainer"),
);
const AskToAnswerContainer = lazy(() => import("../AskToAnswerContainer"));
const BulkUploadAnswers = lazy(() => import("../BulkUploadAnswers"));
const QuestionnaireDashboardContainer = lazy(
  () => import("../QuestionnaireDashboardContainer"),
);

const AltitudeReportsContainer = lazy(
  () => import("../AltitudeReportsContainer"),
);
const AssuranceProfileRoutes = lazy(
  () => import("../AssuranceProfilesContainer"),
);
const HomepageContainer = lazy(() => import("../Homepage"));
const KnowledgeLibraryContainer = lazy(
  () => import("../KnowledgeLibraryContainer"),
);
const MissionControlContainer = lazy(
  () => import("../MissionControlContainer"),
);
const PRISMQuestionnaireContainer = lazy(
  () => import("../PRISMQuestionnaireContainer"),
);
const QuestionnairesContainer = lazy(
  () => import("../QuestionnairesContainer"),
);
const SuggestionsContainer = lazy(() => import("../SuggestionsContainer"));
const SuggestedEditsBadgedText = lazy(
  () =>
    import(
      "../SuggestionsContainer/components/SuggestedEdits/SuggestedEditsBadge"
    ),
);
const VendorAssessRoutes = lazy(() => import("../VendorAssessContainer"));

const LOGIN_PAGE_PATHS = Object.values(LoginPagePath);
const useSetDarkTheme = (): [(darkMode: boolean) => void, boolean] => {
  const [loading, setLoading] = useState(false);

  const setTheme = (darkMode: boolean) => {
    if (
      (darkMode && document.documentElement.classList.contains("dark")) ||
      (!darkMode && !document.documentElement.classList.contains("dark"))
    ) {
      return;
    }

    setLoading(true);
    document.documentElement.classList.add("pause-transition");
    setTimeout(() => {
      if (darkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      setTimeout(() => {
        document.documentElement.classList.remove("pause-transition");
        setLoading(false);
      }, 50);
    }, 10);
  };
  return [setTheme, loading];
};

const SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    name: "Home",
    component: HomepageContainer,
    icon: <LuHome />,
    path: "/homepage",
    enablingPermissions: [Permission.VIEW_QUESTIONNAIRE_TASKS],
    externalFacing: true,
  },
  {
    name: "Questionnaire Concierge",
    component: QuestionnaireDashboardContainer,
    icon: <BellIcon className={styles.BellIcon} />,
    path: "/dashboard",
    requiresCompanyAffiliation: true,
    enablingPermissions: [
      Permission.VIEW_ALL_QUESTIONNAIRE_TASKS,
      Permission.VIEW_QUESTIONNAIRE_TASKS,
    ],
    externalFacing: true,
  },
  {
    name: "Pending Questions",
    component: PendingQuestionsCustomerContainer,
    icon: <QuestionCircleOutlined />,
    path: "/pending-questions",
    requiresCompanyAffiliation: true,
    enablingPermissions: [Permission.VIEW_PENDING_QUESTIONS],
    externalFacing: true,
    enablingFeatureFlag: "pending-questions-enabled",
  },
  {
    name: "Knowledge Library",
    component: KnowledgeLibraryContainer,
    icon: <BsBook />,
    path: "/knowledge-library",
    requiresCompanyAffiliation: true,
    enablingPermissions: [Permission.VIEW_QUESTIONS],
    externalFacing: true,
  },
  {
    name: "SecurityPal Copilot",
    component: CopilotContainer,
    icon: <IoSparklesSharp />,
    path: "/copilot",
    requiresCompanyAffiliation: true,
    enablingPermissions: [Permission.ASK_COPILOT],
    externalFacing: true,
  },
  {
    name: "Assurance Profile",
    component: AssuranceProfileRoutes,
    icon: <ProfileOutlined />,
    path: "/assurance-profiles",
    requiresCompanyAffiliation: true,
    enablingPermissions: [Permission.CUSTOMER_ASSURANCE_VIEW_PROFILES],
    externalFacing: true,
  },
  {
    name: "Vendor Assess (TPRM)",
    component: VendorAssessRoutes,
    icon: <HiOutlineClipboardCheck />,
    path: "/vendor-assess",
    requiresCompanyAffiliation: true,
    enablingFeatureFlag: "vendor-assess-enabled",
    enablingPermissions: [
      Permission.VENDOR_ASSESS_REQUEST_INFO,
      Permission.VENDOR_ASSESS_VIEW_ASSESSMENT,
    ],
    externalFacing: true,
  },
  {
    name: "Mission Control",
    component: MissionControlContainer,
    icon: <MailOutlined />,
    path: "/mission-control",
    enablingPermissions: [Permission.MISSION_CONTROL_VIEW_THREADS],
  },
  {
    name: "Ask to Answer",
    component: AskToAnswerContainer,
    icon: <ReconciliationOutlined />,
    path: "/ask-to-answer",
    enablingFeatureFlag: "access-ask-to-answer",
    enablingPermissions: [Permission.GET_ANSWER_REQUESTS],
  },
  {
    name: "Bulk Upload Answers",
    component: BulkUploadAnswers,
    icon: <CloudUploadOutlined />,
    path: "/bulk-upload-answers",
    enablingPermissions: [Permission.BULK_ADD_QUESTIONS],
  },
  {
    name: "Questionnaires",
    component: QuestionnairesContainer,
    icon: <DiffOutlined />,
    path: "/questionnaires",
    enablingPermissions: [Permission.VIEW_QUESTIONNAIRES],
  },
  {
    name: "Pending Questions",
    component: PendingQuestionsInternalContainer,
    icon: <QuestionCircleOutlined />,
    path: "/pending-questions-internal",
    enablingPermissions: [Permission.CREATE_PENDING_QUESTIONS],
    enablingFeatureFlag: "pending-questions-enabled",
  },
  {
    name: "PRISM Sheets",
    component: PRISMQuestionnaireContainer,
    icon: <DiffOutlined />,
    path: "/prism-questionnaire",
    enablingPermissions: [Permission.ADD_QUESTIONNAIRES],
    externalFacing: false,
  },
  {
    name: <SuggestedEditsBadgedText text="Suggestions" />,
    component: SuggestionsContainer,
    icon: <EditOutlined />,
    path: "/suggestions",
    enablingPermissions: [Permission.VIEW_SUGGESTED_EDITS],
  },
  {
    name: "Altitude Reports",
    component: AltitudeReportsContainer,
    icon: <FileTextOutlined />,
    path: "/altitude-reports",
    enablingFeatureFlag: "altitude-reports-enabled",
    enablingPermissions: [Permission.ALTITUDE_REPORTS_VIEW_REPORTS],
    externalFacing: true,
  },
  {
    name: "Refer SecurityPal",
    component: ReferralContainer,
    icon: <HeartOutlined />,
    path: "/referral",
    enablingPermissions: [Permission.SEND_REFERRALS],
    enablingFeatureFlag: "referral-enabled",
    externalFacing: true,
    isBottomMenu: true,
  },
];

const ADMIN_PANEL_SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    name: "Companies",
    component: CompaniesContainer,
    icon: <ContactsOutlined />,
    path: "/admin/companies",
    enablingPermissions: [Permission.VIEW_ADMIN_PANEL],
  },
];

const PublicRoutes = (): JSX.Element => {
  const [setDarkMode, loading] = useSetDarkTheme();
  useEffect(() => {
    setDarkMode(false);
  }, [setDarkMode]);

  if (loading) {
    return <></>;
  }

  return (
    <Suspense fallback={<CenteredSpinner />}>
      <AppRoutes>
        <Route path={LoginPagePath.Login} element={<LoginContainer />} />
        <Route path={LoginPagePath.Register} element={<RegisterContainer />} />
        <Route
          path={`${LoginPagePath.Register}/:uidb64/:token`}
          element={<RegisterContainer />}
        />
        <Route path={LoginPagePath.ResetPassword} element={<ResetPassword />} />
        <Route
          path={`/authentication${LoginPagePath.ResetPassword}/:uidb64/:token`}
          element={<ResetPassword />}
        />
        <Route
          path="/answer-request/:url"
          element={<AnswerRequestContainer />}
        />
        <Route
          path="/customer-assurance/data-room/:subdomain/:dataRoomLinkId"
          element={<DataRoomContainer />}
        />
        <Route
          path="/customer-assurance/private-data-room/:privateDataRoomLinkId/*"
          element={<PrivateDataRoomFlowContainer />}
        />
        <Route
          path="*"
          element={<Navigate to={LoginPagePath.Login} replace />}
        />
      </AppRoutes>
    </Suspense>
  );
};

const PrivateRoutes = (): JSX.Element => {
  // sets up a shared websocket connection
  useInitializeGeneralSocket();

  const dispatch = useDispatch();

  // Needed in order to ensure products are fetched full-stop.
  const { isLoading: isLoadingProducts } = useGetProductsQuery();
  const { data: userData, isLoading: isLoadingUserData } = useGetMeQuery();

  const isDarkModeOn = useAppSelector(
    (state) => state[LAYOUT_KEY].isDarkModeOn,
  );
  const [setDarkMode] = useSetDarkTheme();
  useEffect(() => {
    const channel = BroadcastChannelManager.getChannel("auth");
    channel.onmessage = (event: MessageEvent) => {
      if (event.data === "logout") dispatch(clearLoggedInState());
    };
    return () => {
      BroadcastChannelManager.closeChannel();
    };
  }, [dispatch]);
  useEffect(() => {
    if (isDarkModeOn) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, [isDarkModeOn, setDarkMode]);

  useSetupTracking(userData);

  const validSideMenuItems = useMemo(() => {
    if (!userData) {
      return [];
    }
    return SIDE_MENU_ITEMS.filter((item) =>
      shouldShowSideMenuItem(item, userData),
    );
  }, [userData]);

  if (isLoadingProducts || isLoadingUserData) {
    return (
      <div className={clsx(isDarkModeOn && styles.SuspenseDark)}>
        <CenteredSpinner />
      </div>
    );
  }

  if (!userData) {
    return (
      <Result
        status="403"
        title="Sorry, it appears you don't have access"
        subTitle="Contact your administrator for help."
      />
    );
  }

  if (validSideMenuItems.length === 0) {
    return <></>;
  }

  return (
    <Suspense
      fallback={
        <div className={clsx(isDarkModeOn && styles.SuspenseDark)}>
          <CenteredSpinner />
        </div>
      }
    >
      <ConfigProvider
        theme={{
          algorithm: isDarkModeOn
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
          components: {
            Layout: {
              algorithm: true,
              siderBg: isDarkModeOn ? "#001529" : "#ffffff", // #001529 is default bg for Sider
            },
          },
        }}
      >
        <AppRoutes>
          {!userData.isEnrolledInMFA && (
            <Route
              path={LoginPagePath.EnrollMFA}
              element={<EnrollMFAContainer />}
            />
          )}
          <Route
            path="/"
            element={<Layout sideMenuItems={validSideMenuItems} />}
          >
            {validSideMenuItems.map((item) => (
              <Route
                key={item.path}
                path={`${item.path}/*`}
                element={<item.component />}
              />
            ))}

            <Route
              path="/customer-assurance/data-room/:subdomain/:dataRoomLinkId"
              element={<DataRoomContainer />}
            />
            <Route
              path="/customer-assurance/private-data-room/:privateDataRoomLinkId/*"
              element={<PrivateDataRoomFlowContainer />}
            />
            <Route path="copilot" element={<CopilotContainer />} />
            <Route path="settings" element={<SettingsContainer />} />
            <Route
              index
              element={<Navigate to={validSideMenuItems[0]?.path} replace />}
            />
          </Route>
          <Route
            path="assurance-profiles/:profileId/versions/:versionId/preview"
            element={<ProfileVersionPreview />}
          />
          <Route
            path="/answer-request/:url"
            element={<AnswerRequestContainer />}
          />

          {/* Admin Panel Routes */}
          {hasPermission(userData.user, Permission.VIEW_ADMIN_PANEL) && (
            <Route
              path="/admin"
              element={
                <Layout
                  sideMenuItems={ADMIN_PANEL_SIDE_MENU_ITEMS}
                  minItems={0}
                />
              }
            >
              {ADMIN_PANEL_SIDE_MENU_ITEMS.map((item) => (
                <Route
                  key={item.path}
                  path={`${item.path}/*`}
                  element={<item.component />}
                />
              ))}
              <Route index element={<AdminPanelContainer />} />
            </Route>
          )}
          <Route path="/api-docs/*" element={<DocsContainer />} />

          <Route
            path="*"
            element={<Navigate to={validSideMenuItems[0]?.path} replace />}
          />
        </AppRoutes>
      </ConfigProvider>
    </Suspense>
  );
};

interface PartialAuthRoutesProps {
  userAuth: UserAuthDataPartial;
}

const PartialAuthRoutes = ({
  userAuth,
}: PartialAuthRoutesProps): JSX.Element => {
  const [setDarkMode, loading] = useSetDarkTheme();
  setDarkMode(false);

  if (loading) {
    return <></>;
  }

  return (
    <Suspense fallback={<CenteredSpinner />}>
      <AppRoutes>
        <Route
          path={LoginPagePath.ValidateMFA}
          element={<ValidateMFAContainer />}
        />
        <Route
          path={LoginPagePath.EnrollMFA}
          element={<EnrollMFAContainer />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={
                userAuth.isEnrolledInMFA
                  ? LoginPagePath.ValidateMFA
                  : LoginPagePath.EnrollMFA
              }
            />
          }
        />
      </AppRoutes>
    </Suspense>
  );
};

const App = (): JSX.Element => {
  const userAuth = useAppSelector((state) => state[USER_AUTH_KEY].userAuthData);
  const location = useLocation();
  const navigate = useNavigate();
  const authContinuationPathnameRef = useRef<string>();
  useEffect(() => {
    const needsAuth = !userAuth || userAuth.authResultType === "needs_mfa";
    if (needsAuth && !authContinuationPathnameRef.current) {
      authContinuationPathnameRef.current = location.pathname;
    }
    if (!needsAuth && authContinuationPathnameRef.current) {
      navigate(authContinuationPathnameRef.current, { replace: true });
      authContinuationPathnameRef.current = undefined;
    }
  }, [userAuth, navigate, location]);

  // TODO (#6574): find a better way to generalize hiding the nav bar
  const hideNavBar =
    (location.pathname.startsWith("/assurance-profiles") &&
      location.pathname.includes("/preview")) ||
    LOGIN_PAGE_PATHS.some((path) => location.pathname.includes(path));

  return (
    <div className={styles.AppContainer}>
      <AnnouncementsContainer />
      {!hideNavBar && <NavBar />}
      {userAuth ? (
        userAuth.authResultType !== "needs_mfa" ? (
          <PrivateRoutes />
        ) : (
          <PartialAuthRoutes userAuth={userAuth} />
        )
      ) : (
        <PublicRoutes />
      )}
    </div>
  );
};

export default App;
