import dayjs from "dayjs";
import { HiCheckCircle, HiDotsCircleHorizontal } from "react-icons/hi";
import {
  CertificationSelectionStatus,
  CustomerAssuranceDocument,
  DocumentGroupWithDocuments,
  GroupedDocuments,
  ISODateStr,
  SortDirections,
} from "./types/assuranceProfile";

export const statusIcon = (
  status: CertificationSelectionStatus,
): JSX.Element => {
  switch (status) {
    case "completed":
      return <HiCheckCircle color="green" />;
    case "inProgress":
      return <HiDotsCircleHorizontal color="gold" />;
    default:
      return <></>;
  }
};

export const publishedDateString = (d: ISODateStr | null): string => {
  if (d === null) {
    return "Unpublished";
  }

  return dayjs(d).format("MMMM DD, YYYY");
};

export const ensureExhaustive = (param: never): never => {
  throw new Error(`should not reach here: ${param}`);
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertSortOrder = (
  order: "ascend" | "descend",
): SortDirections => {
  switch (order) {
    case "ascend":
      return SortDirections.ascending;
    case "descend":
      return SortDirections.descending;
    default:
      return ensureExhaustive(order);
  }
};

export const getDocumentsArrangedByGroup = (
  documents: CustomerAssuranceDocument[],
) => {
  const documentsArrangedByGroup: DocumentGroupWithDocuments[] = [];
  const documentsWithNoGroup: CustomerAssuranceDocument[] = [];

  documents.forEach((doc) => {
    const group = doc.relationships.group;

    if (!group) {
      documentsWithNoGroup.push(doc);
      return;
    }

    const existingGroup = documentsArrangedByGroup.find(
      (g) => g.id === group?.id,
    );

    if (!existingGroup) {
      documentsArrangedByGroup.push({
        ...group,
        documents: [doc],
      });
      return;
    }

    existingGroup.documents.push(doc);
  });

  return {
    documentsArrangedByGroup,
    documentsWithNoGroup,
  };
};

export const getGroupedDocumentsSelectOption = (
  documentsArrangedByGroup: DocumentGroupWithDocuments[],
  documentsWithNoGroup: CustomerAssuranceDocument[],
) => {
  const groupedDocuments: GroupedDocuments = [];

  documentsArrangedByGroup.forEach((group) => {
    const title = group.attributes.title;
    groupedDocuments.push({
      label: title,
      options: group.documents.map((doc) => ({
        label: doc.attributes.title,
        value: doc.id,
      })),
    });
  });
  if (documentsWithNoGroup.length === 0) {
    return groupedDocuments;
  }
  groupedDocuments.push({
    label: "Ungrouped",
    options: documentsWithNoGroup.map((doc) => ({
      label: doc.attributes.title,
      value: doc.id,
    })),
  });

  return groupedDocuments;
};
