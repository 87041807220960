import { Literal, Record, Static, String, Union } from "runtypes";
import { ensureExhaustive } from "shared_frontend/src/utils";
import { runtypeFromEnum } from "../../../utils/runtypes";
import {
  CompanyRT,
  DomainCaptureRT,
  ISODateStrRT,
  UserRT,
} from "../../API/types";
import { RequestThreadIdRT } from "./base";
import { LabelRT } from "./labels";

const RequestThreadEventIdRT = String.withBrand("RequestThreadEventId");
export type RequestThreadEventId = Static<typeof RequestThreadEventIdRT>;

export enum RequestThreadEventType {
  EXTERNAL_ASSIGNED = "external_assigned",
  EXTERNAL_UNASSIGNED = "external_unassigned",

  ASSIGNED = "assigned",
  UNASSIGNED = "unassigned",

  ARCHIVED = "archived",
  UNARCHIVED = "unarchived",

  LABEL_ADDED = "label_added",
  LABEL_REMOVED = "label_removed",

  INBOX_MOVED = "inbox_moved",
}
export const RequestThreadEventTypeRT = runtypeFromEnum(RequestThreadEventType);

export enum RequestThreadEventSourceType {
  USER = "user",
  RULE = "rule",
  DOMAIN_CAPTURE = "domain_capture",
}

export enum RequestThreadEventRule {
  ARCHIVE_GOOGLE_COMMENT = "archive_google_comment",
  C_SAT_SURVEY = "c_sat_survey",
  CIRRT_INBOX = "cirrt_inbox",
  OUT_OF_OFFICE = "out_of_office",
  RESPOND_WITHIN_HOUR = "respond_within_hour",
  SQ_RETURNED_TAGS = "sq_returned_tags",
  ANSWER_LIBRARY_UPDATE_READY = "answer_library_update_ready",
  KNOWLEDGE_LIBRARY_UPDATE = "knowledge_library_update",
  OUT_OF_SCOPE = "out_of_scope",
  AWAITING_RESPONSE_FOLLOW_UP = "awaiting_response_follow_up",
  RETURN_COMPLETED_QUESTIONNAIRE = "return_completed_questionnaire",
  TASK_ARCHIVED_BY_CUSTOMER = "task_archived_by_customer",
}

export const ruleDisplayName = (rule: RequestThreadEventRule): string => {
  switch (rule) {
    case RequestThreadEventRule.ARCHIVE_GOOGLE_COMMENT:
      return "Archive Google Comment";
    case RequestThreadEventRule.C_SAT_SURVEY:
      return "C-SAT Survey";
    case RequestThreadEventRule.CIRRT_INBOX:
      return "CIRRT Inbox";
    case RequestThreadEventRule.OUT_OF_OFFICE:
      return "Out of Office";
    case RequestThreadEventRule.RESPOND_WITHIN_HOUR:
      return "Respond Within Hour";
    case RequestThreadEventRule.SQ_RETURNED_TAGS:
      return "SQ Returned Tags";
    case RequestThreadEventRule.ANSWER_LIBRARY_UPDATE_READY:
      return "Answer Library Update Ready";
    case RequestThreadEventRule.KNOWLEDGE_LIBRARY_UPDATE:
      return "Knowledge Library Update";
    case RequestThreadEventRule.OUT_OF_SCOPE:
      return "Out of Scope";
    case RequestThreadEventRule.AWAITING_RESPONSE_FOLLOW_UP:
      return "Awaiting Response Follow Up";
    case RequestThreadEventRule.RETURN_COMPLETED_QUESTIONNAIRE:
      return "Return Completed Questionnaire";
    case RequestThreadEventRule.TASK_ARCHIVED_BY_CUSTOMER:
      return "Task Archived by Customer";
    default:
      return ensureExhaustive(rule);
  }
};

const RequestThreadEventSourceTypeRT = runtypeFromEnum(
  RequestThreadEventSourceType,
);

export enum RequestThreadEventTargetType {
  COMPANY = "company",
  LABEL = "label",
  USER = "user",

  INBOX = "inbox",
}
const RequestThreadEventTargetTypeRT = runtypeFromEnum(
  RequestThreadEventTargetType,
);

const RequestThreadEventSourceRT = Record({
  id: String.nullable(),
  type: RequestThreadEventSourceTypeRT,
  name: String,
  object: Union(UserRT, DomainCaptureRT).nullable(),
});
export type RequestThreadEventSource = Static<
  typeof RequestThreadEventSourceRT
>;

const RequestThreadEventTargetRT = Record({
  id: String.nullable(),
  type: RequestThreadEventTargetTypeRT.nullable(),
  name: String.nullable(),
  object: Union(CompanyRT, UserRT, LabelRT).nullable(),
});
export type RequestThreadEventTarget = Static<
  typeof RequestThreadEventTargetRT
>;

export const RequestThreadEventRT = Record({
  id: RequestThreadEventIdRT,
  type: Literal("requestThreadEvent"),
  attributes: Record({
    requestThreadId: RequestThreadIdRT,
    eventType: RequestThreadEventTypeRT,
    createdAt: ISODateStrRT,
  }),
  relationships: Record({
    source: RequestThreadEventSourceRT,
    target: RequestThreadEventTargetRT,
  }),
});
export type RequestThreadEvent = Static<typeof RequestThreadEventRT>;
