import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { captureException } from "@sentry/react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { BaseOptionType } from "antd/es/select";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  CustomerAssuranceDocumentId,
  NdaType,
  ProfileVersion,
} from "../types/assuranceProfile";
import {
  getDocumentsArrangedByGroup,
  getGroupedDocumentsSelectOption,
} from "../utils";
import CustomAgreementCheckbox from "./CustomAgreement";
import NdaCheckbox from "./FileBasedNdaCheckbox";
import styles from "./Form.module.scss";
import { relationshipToCompanyOptions } from "./constants";

export interface RequestInfoFormParams {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  relationshipToCompany: string;
  hasCustomQuestionnaire?: boolean;
  comment?: string;
  documentIds?: CustomerAssuranceDocumentId[];
}

export enum FormField {
  documents = "documentIds",
  hasCustomQuestionnaire = "hasCustomQuestionnaire",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  companyName = "companyName",
  relationshipToCompany = "relationshipToCompany",
  comment = "comment",
  agreesToSecurityPalPolicies = "agreesToSecurityPalPolicies",
  agreesToCustomPolicy = "agreesToCustomPolicy",
  agreesToNDA = "agreesToNDA",
}

interface RequestInfoFormProps {
  disabled: boolean;
  profileVersion: ProfileVersion;
  turnstileKey?: string;
  onFinish: (
    params: RequestInfoFormParams,
    turnstileToken: string,
  ) => Promise<void>;
}
const RequestInfoForm = ({
  disabled,
  profileVersion,
  turnstileKey,
  onFinish,
}: RequestInfoFormProps) => {
  const [privacyPolicyUrlClicked, setPrivacyPolicyUrlClicked] = useState(false);
  const [termsOfServiceUrlClicked, setTermsOfServiceUrlClicked] =
    useState(false);
  const [documentSelectSearchString, setDocumentSelectSearchString] =
    useState("");
  const [hasErrors, setHasErrors] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const turnstileRef = useRef<TurnstileInstance>();

  const [form] = Form.useForm<RequestInfoFormParams>();

  const requestSettings =
    profileVersion.relationships.requestInformationSettings.attributes;
  const hasCustomAgreements =
    !!requestSettings.privacyPolicyUrl || !!requestSettings.termsOfServiceUrl;

  const showSecurityPalPolicyCheckbox =
    requestSettings.showSecurityPalPrivacyPolicy ||
    requestSettings.showSecurityPalTermsOfService;

  const isSecurityPalPolicyCheckboxChecked = Form.useWatch(
    FormField.agreesToSecurityPalPolicies,
    form,
  );

  const isSecurityPalCheckboxConditionSatisfied =
    !showSecurityPalPolicyCheckbox || isSecurityPalPolicyCheckboxChecked;

  const securityPalPolicyCheckboxEnabled = () => {
    if (requestSettings.showSecurityPalPrivacyPolicy) {
      if (requestSettings.showSecurityPalTermsOfService) {
        return termsOfServiceUrlClicked && privacyPolicyUrlClicked;
      } else {
        return privacyPolicyUrlClicked;
      }
    } else {
      if (requestSettings.showSecurityPalTermsOfService) {
        return termsOfServiceUrlClicked;
      } else {
        captureException("Function should not run. Component should be hidden");
        return false;
      }
    }
  };

  const isSubmitDisabled = () => {
    let submitEnabled =
      isSecurityPalCheckboxConditionSatisfied &&
      isNDACheckboxConditionSatisfied;
    if (hasCustomAgreements) {
      submitEnabled = submitEnabled && isCustomAgreementCheckboxChecked;
    }
    return !submitEnabled || hasErrors;
  };

  const isIroncladViewNextDisabled = () => {
    let submitEnabled = isSecurityPalCheckboxConditionSatisfied;
    if (hasCustomAgreements) {
      submitEnabled = submitEnabled && isCustomAgreementCheckboxChecked;
    }
    return !submitEnabled || hasErrors;
  };

  const isCustomQuestionnaireChecked = Form.useWatch(
    FormField.hasCustomQuestionnaire,
    form,
  );
  const isCustomAgreementCheckboxChecked = Form.useWatch(
    FormField.agreesToCustomPolicy,
    form,
  );

  const isNDACheckboxChecked = Form.useWatch(FormField.agreesToNDA, form);

  // Sort alphabetically. Default sort from backend is createdAt which is used in documents table
  const privateSortedDocuments = useMemo(
    () =>
      profileVersion.relationships.documents
        .filter((doc) => !doc.attributes.isPublic)
        .sort((a, b) => a.attributes.title.localeCompare(b.attributes.title)),
    [profileVersion.relationships.documents],
  );

  const documentOptions = useMemo(
    () =>
      privateSortedDocuments.map((doc) => ({
        label: doc.attributes.title,
        value: doc.id,
      })),
    [privateSortedDocuments],
  );

  const options: BaseOptionType[] = useMemo(() => {
    const { documentsArrangedByGroup, documentsWithNoGroup } =
      getDocumentsArrangedByGroup(privateSortedDocuments);

    return documentsArrangedByGroup.length > 0
      ? getGroupedDocumentsSelectOption(
          documentsArrangedByGroup,
          documentsWithNoGroup,
        )
      : documentOptions;
  }, [privateSortedDocuments, documentOptions]);

  const selectedDocs = Form.useWatch(FormField.documents, form);
  const allDocsSelected = selectedDocs?.length === documentOptions.length;

  const nda = profileVersion.relationships.nda;

  const hasDocusignEnabled =
    !!nda &&
    nda.type === NdaType.DOCUSIGN &&
    !!nda.attributes.docusignTemplateId;

  const showFileBasedNda = nda?.type === NdaType.FILE;
  const isIroncladNda = nda?.type === NdaType.IRONCLAD;

  const isNDACheckboxConditionSatisfied =
    !showFileBasedNda || !!isNDACheckboxChecked;

  const handleFormChange = () => {
    setHasErrors(form.getFieldsError().some(({ errors }) => errors.length));
  };

  const handleDocumentSelectAll = () => {
    if (setDocumentSelectSearchString.length === 0) {
      // search is empty. Select all
      form.setFieldValue(FormField.documents, documentOptions);
    } else {
      const docValues: string[] = (
        form.getFieldValue(FormField.documents) ?? []
      ).concat(
        documentOptions
          .filter((option) => {
            return (
              option?.label
                .toLowerCase()
                .includes(documentSelectSearchString.toLowerCase()) ?? false
            );
          })
          .map((doc) => doc.value),
      );
      const uniqueDocs = Array.from(new Set(docValues));
      form.setFieldValue(FormField.documents, uniqueDocs);
    }
    setDocumentSelectSearchString("");
  };

  const resetTurnstile = () => {
    turnstileRef.current?.reset();
  };

  useEffect(() => {
    // Context for why we're adding this eventListener:
    // https://github.com/SecurityPal/security-pal/pull/12082
    window.addEventListener("focus", resetTurnstile);
    return () => {
      window.removeEventListener("focus", resetTurnstile);
    };
  }, []);

  return (
    <Form<RequestInfoFormParams>
      form={form}
      layout="vertical"
      onFieldsChange={handleFormChange}
      disabled={disabled}
      onFinish={async (values) => {
        try {
          setIsLoading(true);

          if (!values.documentIds?.length && !values.hasCustomQuestionnaire) {
            message.error(
              "Please request at least one policy or document, or a custom questionnaire.",
            );
            return Promise.reject();
          }

          await onFinish(values, turnstileRef.current?.getResponse() ?? "");
          form.resetFields();
        } catch {
          //no-op
        } finally {
          setIsLoading(false);
          turnstileRef.current?.reset();
        }
      }}
    >
      <Form.Item
        label={<span className="TileText">Private Policies and Documents</span>}
        name={FormField.documents}
      >
        <Select
          className={styles.Select}
          dropdownRender={(menu) => (
            <>
              {!allDocsSelected && (
                <>
                  <Button
                    className={styles.SelectAllButton}
                    type="text"
                    onClick={handleDocumentSelectAll}
                  >
                    <strong>Select all shown</strong>
                  </Button>
                  <Divider className={styles.SelectAllDivider} />
                </>
              )}
              {menu}
            </>
          )}
          mode="multiple"
          allowClear
          showSearch
          placeholder="Search..."
          filterOption={(inputVal, option) =>
            option?.label.toLowerCase().includes(inputVal.toLowerCase()) ??
            false
          }
          options={options}
          onSearch={(searchString) => {
            setDocumentSelectSearchString(searchString);
          }}
          onChange={() => {
            setDocumentSelectSearchString("");
          }}
          onClear={() => {
            setDocumentSelectSearchString("");
          }}
        />
      </Form.Item>
      {requestSettings.showCustomQuestionnaireCheckbox && (
        <Form.Item
          initialValue={false}
          name={FormField.hasCustomQuestionnaire}
          valuePropName="checked"
          extra={
            isCustomQuestionnaireChecked && (
              <Typography.Text className="TileText" italic>
                Upon approval, you’ll receive guidelines on how to submit your
                custom security questionnaire to our Questionnaire Concierge
                powered by SecurityPal&trade;.
              </Typography.Text>
            )
          }
        >
          <Checkbox className="TileText">
            Please check this box if you require a custom security questionnaire
            or review to be completed.
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item
        label={<span className="TileText">First Name</span>}
        name={FormField.firstName}
        rules={[{ required: true }]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label={<span className="TileText">Last Name</span>}
        name={FormField.lastName}
        rules={[{ required: true }]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label={<span className="TileText">Email</span>}
        name={FormField.email}
        validateDebounce={1000}
        rules={[{ required: true, type: "email" }]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label={<span className="TileText">Company Name</span>}
        name={FormField.companyName}
        rules={[{ required: true }]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label={
          <span className="TileText">
            Relationship to {profileVersion.attributes.companyName}
          </span>
        }
        name={FormField.relationshipToCompany}
        rules={[{ required: true }]}
      >
        <Select options={relationshipToCompanyOptions} />
      </Form.Item>
      <Form.Item
        label={
          <span className="TileText">
            Message for {profileVersion.attributes.companyName}
          </span>
        }
        name={FormField.comment}
      >
        <TextArea
          className={styles.TextArea}
          maxLength={1000}
          showCount={true}
        />
      </Form.Item>
      {showSecurityPalPolicyCheckbox && (
        <Form.Item
          name={FormField.agreesToSecurityPalPolicies}
          valuePropName="checked"
          extra={
            <p className={styles.CheckboxInformation}>
              You can only check this box once you have clicked on the policy{" "}
              {requestSettings.showSecurityPalPrivacyPolicy &&
              requestSettings.showSecurityPalTermsOfService
                ? "links"
                : "link"}
            </p>
          }
        >
          <Checkbox
            data-testid="agreesToSecurityPalPolicies"
            disabled={disabled || !securityPalPolicyCheckboxEnabled()}
          >
            I agree to SecurityPal&apos;s{" "}
            {requestSettings.showSecurityPalPrivacyPolicy && (
              <a
                href="https://securitypalhq.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                onClick={() => setPrivacyPolicyUrlClicked(true)}
              >
                Privacy Policy
              </a>
            )}
            {requestSettings.showSecurityPalPrivacyPolicy &&
              requestSettings.showSecurityPalTermsOfService && (
                <>&nbsp;and&nbsp;</>
              )}
            {requestSettings.showSecurityPalTermsOfService && (
              <a
                href="https://www.securitypalhq.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
                onClick={() => setTermsOfServiceUrlClicked(true)}
              >
                Terms of Service
              </a>
            )}
            .
          </Checkbox>
        </Form.Item>
      )}
      {showFileBasedNda && (
        <NdaCheckbox
          nda={nda}
          companyName={profileVersion.attributes.companyName}
          disabled={disabled}
          onNdaAccepted={() => form.setFieldValue(FormField.agreesToNDA, true)}
        />
      )}
      {hasCustomAgreements && (
        <Form.Item
          name={FormField.agreesToCustomPolicy}
          valuePropName="checked"
        >
          <CustomAgreementCheckbox disabled={disabled} />
        </Form.Item>
      )}
      {turnstileKey && <Turnstile ref={turnstileRef} siteKey={turnstileKey} />}
      {hasDocusignEnabled && (
        <div className={styles.NdaDisclaimer}>
          <Typography.Text italic type="secondary">
            {
              "You may be required to sign an NDA to view the documents. The NDA will be sent to your email after your request has been approved."
            }
          </Typography.Text>
        </div>
      )}
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={
          isIroncladNda ? isIroncladViewNextDisabled() : isSubmitDisabled()
        }
      >
        {isIroncladNda ? "Next" : "Submit request"}
      </Button>
    </Form>
  );
};

export default RequestInfoForm;
