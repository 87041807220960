import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import CompanySelector from "../../../components/CompanySelector";
import { useGetPendingQuestionsQuery } from "../../../features/API/pendingQuestions";
import { CompanyId } from "../../../features/API/types";
import { QuestionStatusValues } from "../../../features/pendingQuestions/constants";
import { PendingQuestionResponse } from "../../../features/pendingQuestions/types";
import QuestionStatusSelector from "../../PendingQuestionsCustomerContainer/QuestionStatusSelector";
import styles from "./InternalTableView.module.scss";

interface InternalTableViewProps {
  onSelectQuestionToEdit: (pendingQuestion: PendingQuestionResponse) => void;
}

const InternalTableView = ({
  onSelectQuestionToEdit,
}: InternalTableViewProps): JSX.Element => {
  const [_companyId, setCompanyId] = useState<CompanyId | undefined>(undefined);
  const [questionStatus, setQuestionStatus] =
    useState<QuestionStatusValues | null>(null);
  const queryParams = {
    companyId: _companyId,
    ...(questionStatus === "pending" || questionStatus === "complete"
      ? { status: questionStatus }
      : {}),
  };

  const { data: pendingQuestions } = useGetPendingQuestionsQuery(
    _companyId ? queryParams : skipToken,
  );

  const columns: ColumnsType<PendingQuestionResponse> = [
    {
      dataIndex: "question",
      key: "question",
      title: "Question",
      render: (_, question: PendingQuestionResponse) =>
        question.attributes.question,
    },
    {
      key: "Answer",
      title: "Answer",
      width: "10%",
      render: (_, question: PendingQuestionResponse) =>
        question.attributes.answer,
    },
    {
      key: "Answer Details",
      title: "Answer Details",
      render: (_, question: PendingQuestionResponse) =>
        question.attributes.answerDetails,
    },
    {
      key: "Status",
      title: "Status",
      render: (_, question: PendingQuestionResponse) => {
        return (
          <div className={styles.Status}>
            {question.attributes.status === "pending" ? (
              <>
                <Tag icon={<ClockCircleOutlined />} color="gold">
                  Pending
                </Tag>
              </>
            ) : (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Answered
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      key: "Edit",
      title: "Edit",
      render: (_, question: PendingQuestionResponse) => {
        return (
          <div>
            {question.attributes.status === "pending" && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectQuestionToEdit(question);
                }}
                icon={<FormOutlined />}
              >
                Edit Question
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const expandedRowRender = (question: PendingQuestionResponse) => (
    <div>
      <p>
        <strong>Category: </strong>
        {question.attributes.category ?? "Not Categorised"}
      </p>
      <p>
        <strong>Tags: </strong>
        {question.relationships.tags && question.relationships.tags.length > 0
          ? question.relationships.tags
              .map((tag) => tag.attributes.name)
              .join(", ")
          : "Untagged"}
      </p>
      <p>
        <strong>Assignee: </strong>
        {question.relationships.assignedUser?.attributes.name ?? "Unassigned"}
      </p>
      <p>
        <strong>Context: </strong>
        {question.attributes.context || "No context added"}
      </p>
      <p>
        <strong>Notes: </strong>
        {question.attributes.notes ?? "No notes added"}
      </p>
    </div>
  );
  //Creating a table separate then the customer view because it just gives the descriptive view of the pending questions
  return (
    <Table
      className={styles.InternalTable}
      title={() => {
        return (
          <div className={styles.TableTitle}>
            <Typography.Title level={4}>
              Pending Questions Details
            </Typography.Title>
            <div className={styles.Selectors}>
              <QuestionStatusSelector
                onChange={(value) => setQuestionStatus(value)}
                disabled={_companyId ? false : true}
              />
              <CompanySelector
                onChange={(id) => {
                  setCompanyId(id);
                  setQuestionStatus(null);
                }}
              />
            </div>
          </div>
        );
      }}
      columns={columns}
      dataSource={pendingQuestions?.data}
      expandable={{
        expandedRowRender,
        expandIconColumnIndex: -1,
        expandRowByClick: true,
      }}
      rowKey={(record) => record.id}
    />
  );
};
export default InternalTableView;
