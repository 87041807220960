import clsx from "clsx";
import type { ReactNode } from "react";
import SanitizedHTML from "shared_frontend/src/SanitizedHTML/SanitizedHTML";
import type { AnswerLibraryEntry } from "../../features/KnowledgeLibrary/types";
import styles from "./AnswerDetails.module.scss";

const classForAnswer = (answer: string) => {
  switch (answer.toLocaleLowerCase()) {
    case "yes":
      return styles.yes;
    case "no":
      return styles.no;
    case "n/a":
      return styles.not_applicable;
    default:
      return undefined;
  }
};

interface AnswerBubbleProps {
  answer: string;

  className?: string;
}

export const AnswerBubble = ({
  answer,
  className,
}: AnswerBubbleProps): JSX.Element => (
  <span
    className={clsx(styles.AnswerBubble, classForAnswer(answer), className)}
  >
    {answer.toLocaleUpperCase()}
  </span>
);

interface AnswerDetailsProps {
  entry: AnswerLibraryEntry;
  textTransform?: (text: string) => ReactNode;
  htmlTransform?: (text: string) => string;
  knowledgeLibraryImagesEnabled?: boolean;
}

const AnswerDetails = ({
  entry,
  textTransform,
  htmlTransform,
  knowledgeLibraryImagesEnabled = false,
}: AnswerDetailsProps): JSX.Element => {
  const { answer, details, detailsHtml } = entry.attributes;

  return (
    <div>
      {!!answer && (
        <>
          <AnswerBubble answer={answer} className={styles.OffsetAnswer} />
          {/* Without this space, double-clicking on AnswerBubble will also
          highlight first word of `details` */}
          &nbsp;
        </>
      )}
      {/* NOTE: Be careful when removing this feature flag.  Old/existing entries
      won't have detailsHtml, so we will always need to check for both. */}
      {knowledgeLibraryImagesEnabled && detailsHtml ? (
        <SanitizedHTML
          html={htmlTransform ? htmlTransform(detailsHtml) : detailsHtml}
        />
      ) : (
        details && (textTransform ? textTransform(details) : details)
      )}
    </div>
  );
};

export default AnswerDetails;
