import {
  ArrowsAltOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ShareAltOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import { captureException } from "@sentry/react";
import {
  Alert,
  Button,
  Form,
  Grid,
  Input,
  Popover,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { HeaderInfoPopover } from "shared_frontend/src/InfoPopover";
import AnswerSelect from "../../components/AnswerSelect";
import AppHeader from "../../components/AppHeader";
import ColoredTag from "../../components/ColoredTag";
import CompanySelector from "../../components/CompanySelector";
import MultiUserSelector from "../../components/MultiUserSelector";
import QuestionnaireTaskSelector from "../../components/QuestionnaireTaskSelector";
import UserAvatar from "../../components/UserAvatar";
import { useGetMeQuery } from "../../features/API/auth";
import {
  useGetPendingQuestionsQuery,
  useSaveAnswerToPendingQuestionMutation,
} from "../../features/API/pendingQuestions";
import { Permission } from "../../features/API/permissions";
import { CompanyId, InternalRole, UserId } from "../../features/API/types";
import { LAYOUT_KEY, toggleSideBar } from "../../features/Layout";
import type { QuestionnaireTaskId } from "../../features/MissionControl/types/tasks";
import { QuestionStatusValues } from "../../features/pendingQuestions/constants";
import type {
  ListFilterParams,
  PendingQuestionResponse,
  PendingQuestionStatus,
} from "../../features/pendingQuestions/types";
import { BASE_API } from "../../utils/constants";
import { hasGlobalRole } from "../../utils/helper";
import { useAppSelector, useGetCompanyProducts } from "../../utils/hooks";
import { useGetUsersWithPermission } from "../KnowledgeLibraryContainer/hooks";
import { noUserAvatar } from "../PendingQuestionsInternalContainer/components/SvgIcons";
import AssignUserPopover from "./AssignUserPopover";
import style from "./PendingQuestionsCustomerContainer.module.scss";
import QuestionStatusSelector from "./QuestionStatusSelector";
const { useBreakpoint } = Grid;

const PendingQuestionsCustomerContainer = (): JSX.Element => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: meData } = useGetMeQuery();
  const company = meData?.user.relationships?.company;
  const isStaff = meData?.user.attributes.isStaff;
  const [activeCompanyId, setActiveCompanyId] = useState<CompanyId | undefined>(
    company?.id,
  );
  const { data: pendingQuestions, isLoading } = useGetPendingQuestionsQuery(
    narrowSearchParamsToListFilterParams(searchParams),
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const assigneesParam = searchParams.getAll("assignee");
  const [selectedAssignedUsers, setSelectedAssignedUsers] = useState<UserId[]>(
    assigneesParam.length ? (assigneesParam.map(Number) as UserId[]) : [],
  );

  const [selectedQuestionStatus, setSelectedQuestionStatus] =
    useState<QuestionStatusValues>(
      (searchParams.get("status") as QuestionStatusValues) ||
        QuestionStatusValues.all,
    );

  const [openAssignUserPopoverQuestionId, setOpenAssignUserPopoverQuestionId] =
    useState<string | null>(null);
  const [showNoQuestionsInfo, setShowNoQuestionsInfo] = useState<boolean>(true);

  const products = useGetCompanyProducts(activeCompanyId);
  const { users: assignedUsers } = useGetUsersWithPermission(
    Permission.UPDATE_PENDING_QUESTIONS,
    products.map((product) => product.id),
  );
  useEffect(() => {
    setSearchParams((prevParams) => {
      prevParams.delete("assignee");
      prevParams.delete("unassigned");
      selectedAssignedUsers.forEach((userId) => {
        if (userId === -1) {
          prevParams.append("unassigned", "true");
        } else {
          prevParams.append("assignee", userId.toString());
        }
      });
      return prevParams;
    });
  }, [selectedAssignedUsers, setSearchParams]);
  const handleOnSelectQuestionStatus = (value: QuestionStatusValues) => {
    setSelectedQuestionStatus(value);
    setSearchParams((prevParams) => {
      if (value === QuestionStatusValues.all) {
        prevParams.delete("status");
      } else {
        prevParams.set("status", value);
      }
      return prevParams;
    });
  };
  const handleOnSelectQuestionnaire = (value: QuestionnaireTaskId) => {
    setSearchParams((prevParams) => {
      prevParams.set("questionnaireTaskId", value);
      setCurrentPage(1);
      return prevParams;
    });
  };

  const handleOnSelectAssignedUsers = (values: UserId[]) => {
    setSelectedAssignedUsers(values);
    setCurrentPage(1);
  };
  const watchValues = Form.useWatch([], form);

  useEffect(() => {
    if (!pendingQuestions?.data) return;
    if (pendingQuestions?.data.length > 0) {
      setShowNoQuestionsInfo(false);
    }
  }, [pendingQuestions]);

  function narrowSearchParamsToListFilterParams(searchParams: URLSearchParams) {
    const listFilterParams: ListFilterParams = {};
    if (searchParams.has("questionnaireTaskId")) {
      listFilterParams.questionnaireTaskId = searchParams.get(
        "questionnaireTaskId",
      ) as QuestionnaireTaskId;
    }
    if (searchParams.has("assignee")) {
      listFilterParams.assignee = searchParams.getAll("assignee").map(Number);
    }
    if (searchParams.has("status")) {
      listFilterParams.status = searchParams.get(
        "status",
      ) as PendingQuestionStatus;
    }
    if (searchParams.has("category")) {
      listFilterParams.category = searchParams.get("category");
    }
    if (searchParams.has("unassigned")) {
      listFilterParams.unassigned = searchParams.get("unassigned") === "true";
    }
    return listFilterParams;
  }

  const dispatch = useDispatch();
  const isSidebarCollapsed = useAppSelector(
    (state) => state[LAYOUT_KEY].isSidebarCollapsed,
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [pendingQuestions]);
  const FullScreen = () =>
    isSidebarCollapsed ? (
      <ShrinkOutlined onClick={() => dispatch(toggleSideBar())} />
    ) : (
      <ArrowsAltOutlined onClick={() => dispatch(toggleSideBar())} />
    );

  const screenSize = useBreakpoint();
  const TAGS_TO_SHOW = 3;

  const freezeInputs = (question: PendingQuestionResponse): boolean => {
    return (
      question.attributes.status === "complete" &&
      question.relationships.answerLibraryEntry !== null
    );
  };

  const freezeButton = (question: PendingQuestionResponse): boolean => {
    return (
      (watchValues[`answer_${question.id}`] === null ||
        watchValues[`answer_${question.id}`]?.trim() === "") &&
      (watchValues[`answerDetails_${question.id}`] === null ||
        watchValues[`answerDetails_${question.id}`]?.trim() === "")
    );
  };

  const ActionButton = ({
    question,
  }: { question: PendingQuestionResponse }) => {
    const [savePendingQuestion, { isLoading: isLoadingDoneButton }] =
      useSaveAnswerToPendingQuestionMutation();

    const handleSubmit = async (addToLibrary: boolean = false) => {
      try {
        const values = await form.validateFields([
          `answer_${question.id}`,
          `answerDetails_${question.id}`,
        ]);
        const answer = values[`answer_${question.id}`]?.trim();
        const answerDetails = values[`answerDetails_${question.id}`]?.trim();
        await savePendingQuestion({
          pendingQuestionId: question.id,
          answer,
          answerDetails,
          addToLibrary: addToLibrary,
        });
        addToLibrary
          ? message.success("Answer saved to library successfully")
          : message.success("Answer edited successfully");
      } catch (error) {
        addToLibrary
          ? message.error("Upload to Library Failed")
          : message.error("Answer Edit failed");
        captureException(error);
      }
    };
    return (
      <div className={style.ActionButton}>
        {question.attributes.status === "pending" && (
          <Button
            type="primary"
            onClick={() => handleSubmit(false)}
            loading={isLoadingDoneButton}
            disabled={freezeButton(question)}
          >
            Answer Question
          </Button>
        )}
        {question.attributes.status === "complete" &&
          question.relationships.answerLibraryEntry && (
            <Button
              onClick={() => {
                window.open(
                  `${BASE_API}/knowledge-library/question/${question.relationships.answerLibraryEntry?.attributes.canonicalQuestionId}`,
                  "_blank",
                );
              }}
              loading={isLoadingDoneButton}
            >
              Go To Entry
            </Button>
          )}
        {question.attributes.status === "complete" &&
          question.relationships.answerLibraryEntry === null && (
            <>
              <div className={style.EditAddButtons}>
                <Button
                  onClick={() => handleSubmit(false)}
                  loading={isLoadingDoneButton}
                  disabled={freezeButton(question)}
                  className={style.EditButton}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSubmit(true)}
                  loading={isLoadingDoneButton}
                  disabled={freezeButton(question)}
                  className={style.AddToLibraryButton}
                >
                  Add To Library
                </Button>
              </div>
            </>
          )}
      </div>
    );
  };

  const questionCell = (question: PendingQuestionResponse) => {
    return (
      <div>
        <p>{question.attributes.question}</p>
        {question.attributes.context && (
          <p>
            <strong>Context: </strong>
            {question.attributes.context}
          </p>
        )}
        <div className={style.AvatarCategoryContainer}>
          <AssignUserPopover
            pendingQuestion={question}
            popoverOpen={
              openAssignUserPopoverQuestionId === question.id &&
              !freezeInputs(question)
            }
            setPopoverOpen={(open) =>
              open
                ? setOpenAssignUserPopoverQuestionId(question.id)
                : setOpenAssignUserPopoverQuestionId(null)
            }
          >
            <p>
              {question.relationships.assignedUser ? (
                <UserAvatar
                  key={question.relationships.assignedUser.attributes.email}
                  name={question.relationships.assignedUser.attributes.name}
                  email={question.relationships.assignedUser.attributes.email}
                />
              ) : (
                <>{noUserAvatar}</>
              )}
            </p>
          </AssignUserPopover>
          {question.attributes.category && (
            <div className={style.Category}>
              <span>{question.attributes.category}</span>
            </div>
          )}
          <div className={style.Tags}>
            {question.relationships.tags && (
              <Popover
                content={
                  <div>
                    {question.relationships.tags.map((tag) => (
                      <ColoredTag
                        key={tag.id}
                        backgroundColor={
                          (tag.attributes.backgroundColor as string) ??
                          undefined
                        }
                        text={tag.attributes.name}
                      />
                    ))}
                  </div>
                }
                placement="bottom"
                trigger="click"
              >
                <div className={style.TagContainer}>
                  {question.relationships.tags
                    .slice(0, TAGS_TO_SHOW)
                    .map((tag) => (
                      <ColoredTag
                        key={tag.id}
                        backgroundColor={
                          (tag.attributes.backgroundColor as string) ??
                          undefined
                        }
                        text={tag.attributes.name}
                      />
                    ))}
                  {question.relationships.tags.length > TAGS_TO_SHOW && (
                    <ColoredTag
                      text={`+${question.relationships.tags.length - TAGS_TO_SHOW} more`}
                    />
                  )}
                </div>
              </Popover>
            )}
          </div>
          <div>
            <Link
              to={`/dashboard/${question.relationships.questionnaireTaskId}`}
            >
              Source questionnaire
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const detailsCell = (question: PendingQuestionResponse) => {
    return (
      <Form.Item
        name={`answerDetails_${question.id}`}
        initialValue={question.attributes.answerDetails ?? ""}
        className={style.DetailsCellInput}
      >
        <Input.TextArea disabled={freezeInputs(question)} />
      </Form.Item>
    );
  };

  const answerCell = (question: PendingQuestionResponse) => {
    return (
      <Form.Item
        name={`answer_${question.id}`}
        initialValue={question.attributes.answer ?? ""}
        className={style.AnswerCellInput}
      >
        <AnswerSelect disabled={freezeInputs(question)} />
      </Form.Item>
    );
  };

  const statusCell = (question: PendingQuestionResponse) => {
    return (
      <div className={style.Status}>
        {question.attributes.status === "pending" ? (
          <>
            <Tag icon={<ClockCircleOutlined />} color="gold">
              Pending
            </Tag>
          </>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Answered
          </Tag>
        )}
      </div>
    );
  };

  const actionCell = (question: PendingQuestionResponse) => {
    return <ActionButton question={question} />;
  };

  const columns: ColumnsType<PendingQuestionResponse> = [
    {
      dataIndex: "question",
      key: "question",
      title: "Question",
      responsive: ["md"],
      render: (_, question: PendingQuestionResponse) => {
        return questionCell(question);
      },
    },
    {
      key: "Answer",
      title: "Answer",
      width: "10%",
      responsive: ["md"],
      render: (_, question: PendingQuestionResponse) => {
        return answerCell(question);
      },
    },
    {
      key: "Answer Details",
      title: "Answer Details",
      responsive: ["md"],
      render: (_, question: PendingQuestionResponse) => {
        return detailsCell(question);
      },
    },
    {
      key: "Status",
      title: "Status",
      width: "6.5rem",
      responsive: ["md"],
      render: (_, question: PendingQuestionResponse) => {
        return statusCell(question);
      },
    },
    {
      key: "Action",
      title: FullScreen,
      align: "center",
      responsive: ["md"],
      render: (_, question: PendingQuestionResponse) => {
        return actionCell(question);
      },
    },
  ];
  if (screenSize.xs || (screenSize.sm && !screenSize.md)) {
    // if screensize is small or below, show this column
    columns.push({
      title: "",
      render(_, question: PendingQuestionResponse) {
        return (
          <>
            {questionCell(question)}
            {answerCell(question)}
            {detailsCell(question)}
            {statusCell(question)}
            {actionCell(question)}
          </>
        );
      },
    });
  }

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      {showNoQuestionsInfo && isStaff ? (
        <>
          <Alert
            message="No Pending Questions available. Please use the 'Pending
          Question' tab meant for internal users."
            type="info"
            showIcon
          />
        </>
      ) : (
        <>
          <div className={style.ShareButton}>
            <AppHeader
              title={
                <HeaderInfoPopover
                  title="Pending Question Review"
                  content={
                    <Typography.Paragraph>
                      Questions that need further attention that originated from
                      questionnaires
                    </Typography.Paragraph>
                  }
                />
              }
              extra={
                <>
                  {hasGlobalRole(
                    meData?.user,
                    InternalRole.SP_CUSTOMER_SUCCESS,
                  ) && (
                    <>
                      {" "}
                      Company:&nbsp;
                      <CompanySelector
                        value={activeCompanyId}
                        onChange={(id) => setActiveCompanyId(id)}
                      />
                    </>
                  )}
                </>
              }
            />
            <Tooltip title="Share link" mouseEnterDelay={0.5}>
              <Button
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(`${document.location.href}`);
                  message.info("Copied link to clipboard.");
                }}
                icon={<ShareAltOutlined />}
                size="large"
              >
                Share
              </Button>
            </Tooltip>
          </div>
          {pendingQuestions && (
            <Form form={form}>
              <Table
                pagination={{
                  current: currentPage,
                  onChange: (page) => setCurrentPage(page),
                  showSizeChanger: false,
                }}
                dataSource={pendingQuestions.data}
                title={() => (
                  <div className={style.TableTitle}>
                    <div className={style.FilterContainer}>
                      <MultiUserSelector
                        users={assignedUsers.concat({
                          id: -1 as UserId,
                          type: "simpleUser",
                          attributes: {
                            name: "Unassigned",
                            email: "Unassigned",
                          },
                        })}
                        onChange={handleOnSelectAssignedUsers}
                        value={selectedAssignedUsers}
                        onSearch={() => {
                          // do nothing
                        }}
                        placeholder="Assigned To"
                        className={style.SelectAssignedUser}
                      />
                      <QuestionStatusSelector
                        onChange={handleOnSelectQuestionStatus}
                        value={selectedQuestionStatus}
                      />
                      <QuestionnaireTaskSelector
                        companyId={activeCompanyId}
                        onChange={handleOnSelectQuestionnaire}
                      />
                    </div>
                  </div>
                )}
                columns={columns}
                rowKey={(record) => record.id}
              />
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default PendingQuestionsCustomerContainer;
