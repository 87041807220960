import { Dayjs } from "dayjs";
import {
  Array,
  Boolean,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Static,
  String,
} from "runtypes";
import { HexColorRT } from "shared_frontend/src/types/colors";
import {
  CompanyId,
  CompanyRT,
  ISODateStr,
  ISODateStrRT,
  PartialUserRT,
  ProductId,
  ProductIdRT,
  UserId,
  UserRT,
} from "../API/types";
import { PRISMEntryId, QuestionnaireId } from "../Questionnaires/types";
const KnowledgeLibraryTagIdRT = String.withBrand("knowledgeLibraryTagId");
export type KnowledgeLibraryTagId = Static<typeof KnowledgeLibraryTagIdRT>;

export const KnowledgeLibraryTagRT = Record({
  type: Literal("knowledgeLibraryTag"),
  id: KnowledgeLibraryTagIdRT,
  attributes: Record({
    name: String,
    description: String.Or(Null),
    backgroundColor: HexColorRT.nullable(),
    createdAt: ISODateStrRT.nullable(),
    createdBy: String.nullable(),
  }),
});
export type KnowledgeLibraryTag = Static<typeof KnowledgeLibraryTagRT>;

export type KnowledgeLibraryTagParams = {
  name: string;
  productId: ProductId;
  description?: string;
  backgroundColor?: string;
};

export enum KnowledgeLibraryEntityType {
  CANONICAL_QUESTION = "canonicalQuestion",
  DOCUMENT = "document",
}

export type AddOrRemoveKnowledgeLibraryTagsParams = {
  tagIds: KnowledgeLibraryTagId[];
  entityIds: CanonicalQuestionId[] | DocumentId[];
  entityType: KnowledgeLibraryEntityType;
};

export const AnswerLibraryEntryIdRT = Number.withBrand("AnswerLibraryEntryId");
export type AnswerLibraryEntryId = Static<typeof AnswerLibraryEntryIdRT>;
export const CanonicalQuestionIdRT = String.withBrand("CanonicalQuestionId");
export type CanonicalQuestionId = Static<typeof CanonicalQuestionIdRT>;

const documentIdRT = String.withBrand("documentId");
export type DocumentId = Static<typeof documentIdRT>;
const DocumentVersionIdRT = Number.withBrand("documentVersionId");
export type DocumentVersionId = Static<typeof DocumentVersionIdRT>;

const AnswerLibraryEntryAttributesRT = Record({
  canonicalQuestionId: CanonicalQuestionIdRT,
  question: String,
  answer: String.Or(Null),
  category: String.Or(Null),
  details: String.Or(Null),
  notes: String.Or(Null),
  detailsHtml: String.Or(Null),
  createdAt: ISODateStrRT,
  createdFromMerge: Boolean,
  sourceName: Optional(String),
  sourceUrl: Optional(String),
  isMostRecent: Boolean,
  reviewedAt: Optional(ISODateStrRT.nullable()),
  reviewCycle: Number.nullable(),
  minimumProductReviewCycle: Number,
  productIds: Array(ProductIdRT),
});
const AnswerLibraryEntryRelationshipsRT = Record({
  tags: Array(KnowledgeLibraryTagRT),
  createdBy: Optional(UserRT),
  createdByCompany: Optional(CompanyRT),
  reviewedBy: Optional(UserRT.nullable()),
  owners: Array(UserRT),
});
export const AnswerLibraryEntryRT = Record({
  type: Literal("answerLibraryEntry"),
  id: AnswerLibraryEntryIdRT,
  attributes: AnswerLibraryEntryAttributesRT,
  relationships: AnswerLibraryEntryRelationshipsRT,
});
const SimpleQuestionnaireTaskRT = Record({
  type: Literal("simpleQuestionnaireTask"),
  id: String,
  attributes: Record({
    prospect: String,
  }),
});

export type AnswerLibraryEntry = Static<typeof AnswerLibraryEntryRT>;

export const CanonicalQuestionRT = Record({
  type: Literal("canonicalQuestion"),
  id: CanonicalQuestionIdRT,
  attributes: Record({
    question: String,
    answer: String,
    details: String,
    detailsHtml: Optional(String),
    category: String,
    lastUpdatedAt: ISODateStrRT,
    deletedAt: ISODateStrRT.nullable(),
    sourceName: Optional(String),
    sourceUrl: Optional(String),
    notes: String.Or(Null),
    productIds: Array(ProductIdRT),
  }),
  relationships: Optional(
    Record({
      entries: Optional(Array(AnswerLibraryEntryRT)),
      tags: Array(KnowledgeLibraryTagRT),
      owners: Array(UserRT),
      questionnaireTasks: Optional(Array(SimpleQuestionnaireTaskRT)),
    }),
  ),
});
export type CanonicalQuestion = Static<typeof CanonicalQuestionRT>;

export const DocumentVersionRT = Record({
  type: Literal("documentVersion"),
  id: DocumentVersionIdRT,
  attributes: Record({
    filename: String,
    createdAt: ISODateStrRT,
  }),
  relationships: Record({
    createdBy: PartialUserRT.optional().nullable(),
  }),
});
export type DocumentVersion = Static<typeof DocumentVersionRT>;

export const DocumentRT = Record({
  type: Literal("document"),
  id: documentIdRT,
  attributes: Record({
    filename: String,
    name: String,
    description: String.nullable(),
    lastUpdated: ISODateStrRT,
    presignedUrl: Optional(String),
    reviewedAt: Optional(ISODateStrRT.nullable()),
    reviewCycle: Number.nullable(),
    minimumProductReviewCycle: Number,
    productIds: Array(ProductIdRT),
  }),
  relationships: Record({
    tags: Array(KnowledgeLibraryTagRT),
    owners: Array(UserRT),
    versionHistory: Array(DocumentVersionRT),
    reviewedBy: Optional(UserRT.nullable()),
  }),
});
export type Document = Static<typeof DocumentRT>;

export type AddAnswerLibraryEntryParams = ReviewParams & {
  productIds: ProductId[];
  prismEntryId?: PRISMEntryId;
  source?: string;
  question: string;
  answer: string;
  details: string;
  notes: string;
  detailsHtml?: string;
  category: string;
  date: string | null;
  tagIds?: KnowledgeLibraryTagId[];
  ownerUserIds?: UserId[];
};

export interface EditModalParams {
  column: keyof CanonicalQuestion["attributes"];
  entryId: CanonicalQuestionId;
  text: string;
}

export enum AnswerEntrySearchOrdering {
  relevance = "relevance",
  recency = "recency",
}

export enum AnswerEntryFilterMethod {
  and = "and",
  or = "or",
  not = "not",
}

export interface AnswerEntrySearchFilters {
  query: string;
  productIds: ProductId[];
  ordering: AnswerEntrySearchOrdering;
  onlyShowReviewed: boolean;
  returnOutdatedReviewDate: boolean;
  tagsOperator: AnswerEntryFilterMethod;
  categories?: string[] | undefined;
  tags?: KnowledgeLibraryTagId[] | undefined;
  beforeDate?: Dayjs | null;
  afterDate?: Dayjs | null;
  beforeNextReviewDate?: Dayjs | null;
  afterNextReviewDate?: Dayjs | null;
  sourceQuestionnaireIds?: QuestionnaireId[] | undefined;
  ownerUserIds?: UserId[];
  noOwner?: boolean | undefined;
}

export const AnswerEntrySearchOrderingDisplayName: {
  [key in AnswerEntrySearchOrdering]: string;
} = {
  [AnswerEntrySearchOrdering.relevance]: "Relevance",
  [AnswerEntrySearchOrdering.recency]: "Most recent",
};

export interface DocumentSearchFilters {
  query: string;
  productIds: ProductId[];
  tagsOperator: AnswerEntryFilterMethod;
  tags?: KnowledgeLibraryTagId[];
  ownerUserIds?: UserId[];
  onlyShowReviewed: boolean;
  returnOutdatedReviewDate: boolean;
  beforeNextReviewDate?: Dayjs | null;
  afterNextReviewDate?: Dayjs | null;
}

export const BulkDocumentUploadErrorRT = Record({
  meta: Record({
    document: String,
  }),
  status: Number,
  title: String,
});
export type BulkDocumentUploadError = Static<typeof BulkDocumentUploadErrorRT>;

export const DocumentMetaRT = Record({
  errors: Array(BulkDocumentUploadErrorRT),
});
export type DocumentMeta = Static<typeof DocumentMetaRT>;

export const DocumentResultRT = Record({
  document: DocumentRT,
  preview: String,
});
export type DocumentResult = Static<typeof DocumentResultRT>;

export interface DocumentSearchParams {
  query: string;
  filters: DocumentSearchFilters;
  limit: number;
  offset: number;
}

export type ReviewParams = {
  reviewedAt?: ISODateStr;
  reviewedByUserId?: UserId | null;
  reviewCycle?: number | null;
};

export type EditDocumentParams = ReviewParams & {
  documentId: DocumentId;
  name: string;
  description?: string;
  tagIds?: KnowledgeLibraryTagId[];
  ownerUserIds?: UserId[];
  productIds?: ProductId[];
};

export interface UpdateAnswerLibraryEntryParams {
  productId: ProductId;
  entryId: AnswerLibraryEntryId;
  column: string;
  value: string;
}

export type UpdateCanonicalQuestionParams = ReviewParams & {
  canonicalQuestionId: CanonicalQuestionId;
  prismEntryId?: PRISMEntryId;
  question?: string;
  category?: string;
  answer?: string;
  details?: string;
  detailsHtml?: string;
  ownerUserIds?: UserId[];
  tagIds?: KnowledgeLibraryTagId[];
  notes?: string;
  productIds?: ProductId[];
};

export type BulkAddReviewsParams = {
  canonicalQuestionIds: CanonicalQuestionId[];
  reviewedAt: ISODateStr;
  reviewedByUserId?: UserId | null;
};

export type BulkUpdateReviewCycleParams = {
  canonicalQuestionIds: CanonicalQuestionId[];
  reviewCycle: number | null;
};

export type BulkUpdateOwnersParams = {
  canonicalQuestionIds: CanonicalQuestionId[];
  ownerUserIdsToAdd: UserId[];
  ownerUserIdsToRemove: UserId[];
};

export type BulkAddDocReviewsParams = {
  documentIds: DocumentId[];
  reviewedAt: ISODateStr;
  reviewedByUserId?: UserId | null;
};

export type BulkUpdateDocReviewCycleParams = {
  documentIds: DocumentId[];
  reviewCycle: number | null;
};

export type BulkUpdateDocOwnersParams = {
  documentIds: DocumentId[];
  ownerUserIdsToAdd: UserId[];
  ownerUserIdsToRemove: UserId[];
};

export const GetCategoriesResultRT = Record({
  categories: Array(String),
});

export type GetCategoriesResult = Static<typeof GetCategoriesResultRT>;

export type UpdateKnowledgeLibraryTagParams = KnowledgeLibraryTagParams & {
  tagId: KnowledgeLibraryTagId;
};
export const BulkUploadTagsResponseRT = Record({
  message: String,
});

export type BulkUploadTagsResponse = Static<typeof BulkUploadTagsResponseRT>;

export type UploadImagesParams = {
  companyId: CompanyId;
  files: File[];
};

export const ImageFileIdRT = String.withBrand("ImageFileId");
export type ImageFileId = Static<typeof ImageFileIdRT>;

export const ImageFileMappingRT = Record({
  id: ImageFileIdRT,
  filename: String,
});

export const UploadImagesResponseRT = Array(ImageFileMappingRT);
export type UploadImagesResponse = Static<typeof UploadImagesResponseRT>;

export enum PRISMEntryStatus {
  SKIPPED = "Skipped",
  ADDED = "Added",
  UPDATED = "Updated",
  UNPROCESSED = "Unprocessed",
}

export type UpdatePRISMEntryParams = {
  id: PRISMEntryId;
  status: PRISMEntryStatus;
};
