import { skipToken } from "@reduxjs/toolkit/query";
import {
  Array,
  Null,
  Number,
  Optional,
  Record as RecordRT,
  Static,
  String,
  Undefined,
} from "runtypes";
import { privateApi } from "..";
import {
  APIEndpoints,
  CACHE_TIMEOUT_8_MIN_IN_SECONDS,
} from "../../../utils/constants";
import { JSONAPI } from "../../../utils/jsonAPI";
import {
  AddAnswerLibraryEntryParams,
  AddOrRemoveKnowledgeLibraryTagsParams,
  AnswerEntrySearchFilters,
  AnswerLibraryEntry,
  AnswerLibraryEntryRT,
  BulkAddDocReviewsParams,
  BulkAddReviewsParams,
  BulkUpdateDocOwnersParams,
  BulkUpdateDocReviewCycleParams,
  BulkUpdateOwnersParams,
  BulkUpdateReviewCycleParams,
  BulkUploadTagsResponse,
  BulkUploadTagsResponseRT,
  CanonicalQuestion,
  CanonicalQuestionId,
  CanonicalQuestionRT,
  Document,
  DocumentId,
  DocumentMeta,
  DocumentMetaRT,
  DocumentRT,
  DocumentResult,
  DocumentResultRT,
  DocumentSearchParams,
  DocumentVersion,
  DocumentVersionId,
  DocumentVersionRT,
  EditDocumentParams,
  GetCategoriesResultRT,
  KnowledgeLibraryTag,
  KnowledgeLibraryTagId,
  KnowledgeLibraryTagParams,
  KnowledgeLibraryTagRT,
  UpdateCanonicalQuestionParams,
  UpdateKnowledgeLibraryTagParams,
  UpdatePRISMEntryParams,
  UploadImagesParams,
  UploadImagesResponse,
  UploadImagesResponseRT,
} from "../../KnowledgeLibrary/types";
import { PRISMEntry, PRISMEntryRT } from "../../Questionnaires/types";
import { SearchQueryIdRT } from "../../SearchMetrics/type";
import {
  FullCompany,
  FullCompanyRT,
  Product,
  ProductId,
  ProductRT,
} from "../types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";
import {
  constructDocumentsQueryParams,
  constructEntriesQueryParams,
} from "./utils";

const GetEntriesMetaRT = RecordRT({
  totalCount: Number,
  searchId: SearchQueryIdRT,
  hybridCount: Optional(Number),
});

type GetEntriesMeta = Static<typeof GetEntriesMetaRT>;

export interface GetEntriesQueryParams {
  answerSearchSettings: AnswerEntrySearchFilters;
  offset?: number;
  limit?: number;
  hybridOffset?: number;
}

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    addAnswerLibraryEntry: mutationEndpointBuilder<
      void,
      AnswerLibraryEntry,
      AddAnswerLibraryEntryParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: AnswerLibraryEntryRT,
      url: () => APIEndpoints.answerLibrary.ADD_ENTRY,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Questionnaire"],
    }),
    bulkUpdateOwners: mutationEndpointBuilder<
      void,
      void,
      BulkUpdateOwnersParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.answerLibrary.BULK_UPDATE_OWNERS,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Product"],
    }),
    bulkUploadAnswers: builder.mutation<void, FormData>({
      query: (params) => ({
        url: APIEndpoints.answerLibrary.BULK_UPLOAD,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["CanonicalQuestion"],
    }),
    bulkUploadDocuments: mutationEndpointBuilder<
      DocumentMeta,
      Document[],
      FormData
    >({
      builder,
      metaRuntype: DocumentMetaRT,
      dataRuntype: Array(DocumentRT),
      url: () => APIEndpoints.documents.BULK_UPLOAD_DOCUMENTS,
      body: (params) => params,
      invalidatesTags: ["Document"],
    }),
    deleteDocument: builder.mutation<void, DocumentId>({
      query: (documentId) => ({
        url: APIEndpoints.documents.DELETE_DOCUMENT,
        method: "POST",
        body: { documentId },
      }),
      invalidatesTags: ["Document"],
    }),
    editDocument: mutationEndpointBuilder<void, Document, EditDocumentParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: DocumentRT,
      url: () => APIEndpoints.documents.EDIT_DOCUMENT,
      body: (params) => params,
      invalidatesTags: ["Document"],
    }),
    getCategories: queryEndpointBuilder({
      builder,
      metaRuntype: Undefined,
      dataRuntype: GetCategoriesResultRT,
      url: (productIds: ProductId[]) => {
        const urlParams = new URLSearchParams();
        productIds.forEach((productId) =>
          urlParams.append("productIds", productId.toString()),
        );
        return `${APIEndpoints.answerLibrary.GET_CATEGORIES}?${urlParams.toString()}`;
      },
      providesTags: ["Categories"],
    }),
    getCanonicalQuestion: builder.query<
      JSONAPI<void, CanonicalQuestion>,
      CanonicalQuestionId
    >({
      query: (id) => ({
        url: `${APIEndpoints.answerLibrary.GET_CANONICAL_QUESTION}?canonical_question_id=${id}`,
      }),
      providesTags: ["CanonicalQuestion"],
    }),
    getEntries: queryEndpointBuilder<
      GetEntriesMeta,
      AnswerLibraryEntry[],
      GetEntriesQueryParams
    >({
      builder,
      metaRuntype: GetEntriesMetaRT,
      dataRuntype: Array(AnswerLibraryEntryRT),
      url: ({ answerSearchSettings, offset, limit, hybridOffset }) =>
        `${APIEndpoints.answerLibrary.GET_ENTRIES}?` +
        constructEntriesQueryParams(
          answerSearchSettings,
          limit ?? 0,
          offset ?? 0,
          hybridOffset,
        ),
      providesTags: ["CanonicalQuestion"],
    }),

    getDocumentMetadata: builder.query<JSONAPI<void, Document>, DocumentId>({
      query: (documentId) => ({
        url: `${APIEndpoints.documents.GET_DOCUMENT_METADATA}?documentId=${documentId}`,
      }),
      providesTags: ["Document"],
    }),
    findSearchableQuery: queryEndpointBuilder<
      void,
      string | null,
      { candidates: string[]; productId: ProductId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: String.Or(Null),
      url: ({ candidates, productId }) =>
        `${
          APIEndpoints.answerLibrary.FIND_SEARCHABLE_QUERY
        }?queries=${encodeURIComponent(
          JSON.stringify(candidates),
        )}&productId=${productId}`,
    }),
    bulkAddReviews: mutationEndpointBuilder<void, void, BulkAddReviewsParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.answerLibrary.BULK_ADD_REVIEWS,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Product"],
    }),
    bulkUpdateReviewCycle: mutationEndpointBuilder<
      void,
      void,
      BulkUpdateReviewCycleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.answerLibrary.BULK_UPDATE_REVIEW_CYCLE,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Product"],
    }),
    mergeQuestions: builder.mutation<
      void,
      {
        canonicalQuestionIds: CanonicalQuestionId[];
        question: string;
        category: string;
        answer: string;
        details: string;
        detailsHtml?: string;
      }
    >({
      query: (params) => ({
        url: APIEndpoints.answerLibrary.MERGE_QUESTIONS,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["CanonicalQuestion"],
    }),
    removeCanonicalQuestions: builder.mutation<void, CanonicalQuestionId[]>({
      query: (canonicalQuestionIds) => ({
        url: APIEndpoints.answerLibrary.REMOVE_CANONICAL_QUESTIONS,
        method: "POST",
        body: { canonicalQuestionIds },
      }),
      invalidatesTags: ["CanonicalQuestion", "SuggestedEdit"],
    }),
    searchDocuments: queryEndpointBuilder<
      { totalCount: number },
      DocumentResult[],
      DocumentSearchParams
    >({
      builder,
      metaRuntype: RecordRT({ totalCount: Number }),
      dataRuntype: Array(DocumentResultRT),
      url: ({ query, filters, limit, offset }) => {
        return `${
          APIEndpoints.documents.GET_DOCUMENTS
        }?${constructDocumentsQueryParams(
          query,
          filters,
          limit ?? 10,
          offset ?? 0,
        )}`;
      },
      providesTags: ["Document"],
    }),
    updateCanonicalQuestion: builder.mutation<
      void,
      UpdateCanonicalQuestionParams
    >({
      query: (params) => ({
        url: APIEndpoints.answerLibrary.UPDATE_CANONICAL_QUESTION,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["CanonicalQuestion", "SuggestedEdit", "Questionnaire"],
    }),
    updateProduct: mutationEndpointBuilder<
      void,
      Product,
      { productId: number; reviewCycle?: number }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: ProductRT,
      url: () => APIEndpoints.answerLibrary.UPDATE_PRODUCT,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Document", "Product"],
    }),

    addTags: mutationEndpointBuilder<
      void,
      CanonicalQuestion[] | Document[],
      AddOrRemoveKnowledgeLibraryTagsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(CanonicalQuestionRT).Or(Array(DocumentRT)),
      url: () => APIEndpoints.answerLibrary.ADD_TAGS,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Document"],
    }),
    removeTags: mutationEndpointBuilder<
      void,
      CanonicalQuestion[] | Document[],
      AddOrRemoveKnowledgeLibraryTagsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(CanonicalQuestionRT).Or(Array(DocumentRT)),
      url: () => APIEndpoints.answerLibrary.REMOVE_TAGS,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Document"],
    }),
    getCompanies: queryEndpointBuilder<
      { totalCount: number },
      FullCompany[],
      void
    >({
      builder,
      metaRuntype: RecordRT({ totalCount: Number }),
      dataRuntype: Array(FullCompanyRT),
      url: () => APIEndpoints.answerLibrary.GET_COMPANIES,
      providesTags: ["FullCompany"],
    }),
    createTag: mutationEndpointBuilder<
      void,
      KnowledgeLibraryTag,
      KnowledgeLibraryTagParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: KnowledgeLibraryTagRT,
      url: () => APIEndpoints.answerLibrary.CREATE_TAG,
      body: (params) => params,
      invalidatesTags: ["KnowledgeLibraryTag"],
    }),
    updateTag: mutationEndpointBuilder<
      void,
      KnowledgeLibraryTag,
      UpdateKnowledgeLibraryTagParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: KnowledgeLibraryTagRT,
      url: () => APIEndpoints.answerLibrary.UPDATE_TAG,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Document", "KnowledgeLibraryTag"],
    }),
    getTags: queryEndpointBuilder<void, KnowledgeLibraryTag[], ProductId[]>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(KnowledgeLibraryTagRT),
      url: (productIds) => {
        const searchParams = new URLSearchParams();
        productIds.forEach((productId) =>
          searchParams.append("productIds", productId.toString()),
        );
        return `${
          APIEndpoints.answerLibrary.GET_TAGS
        }?${searchParams.toString()}`;
      },
      providesTags: ["KnowledgeLibraryTag"],
    }),
    deleteTag: mutationEndpointBuilder<
      void,
      void,
      { tagId: KnowledgeLibraryTagId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.answerLibrary.DELETE_TAG,
      body: (params) => params,
      invalidatesTags: ["CanonicalQuestion", "Document", "KnowledgeLibraryTag"],
    }),
    bulkUploadTags: mutationEndpointBuilder<
      void,
      BulkUploadTagsResponse,
      FormData
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: BulkUploadTagsResponseRT,
      url: () => APIEndpoints.answerLibrary.BULK_UPLOAD_TAGS,
      body: (params) => params,
      invalidatesTags: ["KnowledgeLibraryTag"],
    }),
    bulkAddDocumentReviews: mutationEndpointBuilder<
      void,
      void,
      BulkAddDocReviewsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.documents.BULK_ADD_REVIEWS,
      body: (params) => params,
      invalidatesTags: ["Document", "Product"],
    }),
    bulkUpdateDocumentReviewCycle: mutationEndpointBuilder<
      void,
      void,
      BulkUpdateDocReviewCycleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.documents.BULK_UPDATE_REVIEW_CYCLE,
      body: (params) => params,
      invalidatesTags: ["Document", "Product"],
    }),
    bulkUpdateDocumentOwners: mutationEndpointBuilder<
      void,
      void,
      BulkUpdateDocOwnersParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.documents.BULK_UPDATE_OWNERS,
      body: (params) => params,
      invalidatesTags: ["Document", "Product"],
    }),
    createDocumentVersion: mutationEndpointBuilder<
      void,
      DocumentVersion,
      FormData
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: DocumentVersionRT,
      url: () => APIEndpoints.documents.CREATE_DOCUMENT_VERSION,
      body: (params) => params,
      invalidatesTags: ["Document"],
    }),
    deleteDocumentVersion: mutationEndpointBuilder<
      void,
      void,
      { documentId: DocumentId; versionId: DocumentVersionId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.documents.DELETE_DOCUMENT_VERSION,
      body: (params) => params,
      invalidatesTags: ["Document"],
    }),
    bulkDeleteDocuments: mutationEndpointBuilder<
      void,
      void,
      { documentIds: DocumentId[] }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.documents.BULK_DELETE_DOCUMENTS,
      body: (params) => params,
      invalidatesTags: ["Document"],
    }),
    uploadImages: mutationEndpointBuilder<
      void,
      UploadImagesResponse,
      UploadImagesParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: UploadImagesResponseRT,
      url: () => APIEndpoints.answerLibrary.UPLOAD_IMAGES,
      body: (params) => {
        const formData = new FormData();
        formData.append("companyId", params.companyId);
        for (const file of params.files) {
          formData.append("files", file);
        }
        return formData;
      },
    }),
    updatePrismEntry: mutationEndpointBuilder<
      void,
      PRISMEntry,
      UpdatePRISMEntryParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: PRISMEntryRT,
      url: () => APIEndpoints.answerLibrary.UPDATE_PRISM_ENTRY,
      body: (params) => params,
      invalidatesTags: ["Questionnaire"],
    }),
  }),
  overrideExisting: false,
});

export const useGetEntriesQuery = (
  arg: GetEntriesQueryParams | typeof skipToken,
) => {
  return extendedApi.useGetEntriesQuery(arg, {
    refetchOnMountOrArgChange: CACHE_TIMEOUT_8_MIN_IN_SECONDS,
  });
};

export const useGetCanonicalQuestionQuery = (
  arg: CanonicalQuestionId | typeof skipToken,
) => {
  return extendedApi.useGetCanonicalQuestionQuery(arg, {
    refetchOnMountOrArgChange: CACHE_TIMEOUT_8_MIN_IN_SECONDS,
  });
};

export const {
  useAddAnswerLibraryEntryMutation,
  useCreateTagMutation,
  useBulkAddReviewsMutation,
  useBulkAddDocumentReviewsMutation,
  useBulkDeleteDocumentsMutation,
  useBulkUpdateDocumentReviewCycleMutation,
  useBulkUpdateDocumentOwnersMutation,
  useBulkUpdateReviewCycleMutation,
  useBulkUpdateOwnersMutation,
  useBulkUploadAnswersMutation,
  useBulkUploadDocumentsMutation,
  useBulkUploadTagsMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
  useGetCategoriesQuery,
  useGetCompaniesQuery,
  useGetTagsQuery,
  useGetDocumentMetadataQuery,
  useFindSearchableQueryQuery,
  useMergeQuestionsMutation,
  useRemoveCanonicalQuestionsMutation,
  useSearchDocumentsQuery,
  useUpdateCanonicalQuestionMutation,
  useUpdateProductMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useAddTagsMutation,
  useRemoveTagsMutation,
  useCreateDocumentVersionMutation,
  useDeleteDocumentVersionMutation,
  useUploadImagesMutation,
  useUpdatePrismEntryMutation,
} = extendedApi;
