import { DatePickerProps } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ISODateStr } from "../features/API/types";

dayjs.extend(relativeTime);

export const timeAgoString = (d?: ISODateStr): string => {
  return dayjs(d).fromNow();
};

export const readableDateTimeString = (d: ISODateStr): string => {
  const updateDate = new Date(d);
  return `${updateDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })} at ${updateDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  })}`;
};

export const readableDateString = (d: ISODateStr): string => {
  return dayjs(d).format("MMMM DD, YYYY");
};

// Do not allow selecting a future date.
export const disabledDate: DatePickerProps["disabledDate"] = (date) => {
  return date && date > dayjs().endOf("day");
};

// Do not allow selecting a past date.
export const disabledPastDate: DatePickerProps["disabledDate"] = (date) => {
  return date && date.isBefore(dayjs(), "day");
};
