import { v4 as uuidv4 } from "uuid";
const X_REQUEST_ID_HEADER_NAME = "X-Request-ID";

export const uncachedFetch = async (
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> => {
  const params = { ...(init || {}) };
  params.headers = {
    [X_REQUEST_ID_HEADER_NAME]: uuidv4(),
    ...(params.headers || {}),
  };
  const r = await fetch(input, params);
  return r;
};

export const downloadFile = async (url: string): Promise<Blob> => {
  const r = await uncachedFetch(url);
  if (!r.ok) {
    throw new Error(`Failed to download file: ${r.statusText}`);
  }
  return r.blob();
};

export interface FileDownloadResponse {
  signedUrl: string;
  fileName: string;
  contentType: string;
}

export const getFileDownloadResponse = async (
  url: string,
  fetchFunc?: (url: string) => Promise<Response>,
): Promise<FileDownloadResponse> => {
  const func = fetchFunc || uncachedFetch;
  const r = await func(url);
  if (!r.ok) {
    throw new Error(`Failed to download file: ${r.statusText}`);
  }
  const data = await r.json();
  if (!data.signedUrl) {
    throw new Error("No signed URL provided in the response");
  }
  return data;
};

export const downloadFileFromSignedUrl = async (
  url: string,
  fileName?: string,
  fetchFunc?: (url: string) => Promise<Response>,
) => {
  const data = await getFileDownloadResponse(url, fetchFunc);
  const link = document.createElement("a");
  link.href = data.signedUrl;
  link.download = fileName || data.fileName;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFileContentAsBlobFromSignedUrl = async (
  url: string,
  customFetchFunction?: (url: string) => Promise<Response>,
) => {
  const fetchFunctionToUse = customFetchFunction || uncachedFetch;
  const data = await getFileDownloadResponse(url, fetchFunctionToUse);
  const signedUrl = data.signedUrl;
  const fileResponse = await fetch(signedUrl);
  if (!fileResponse.ok) {
    throw new Error(
      `Failed to fetch file: ${fileResponse.status} ${fileResponse.statusText}`,
    );
  }
  return fileResponse.blob();
};
