import { RcFile } from "antd/lib/upload";
import { Undefined } from "runtypes";
import privateApi from ".";
import { APIEndpoints } from "../../utils/constants";
import { GeneralSocket } from "../../utils/webSockets/socket";
import {
  GetMultipleAnswerData,
  GetMultipleAnswerPaginationParams,
  MultipleAnswerQueryParams,
  NewRequestResponse,
  PaginationMeta,
  PromoteRequestParams,
  PromoteRequestResponse,
} from "../Copilot/BulkAnswer/generatedTypes";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

type MultipleAnswerQueryParamsWithFile = MultipleAnswerQueryParams & {
  file: RcFile;
};

export type GetMultipleAnswerParamsWithQueryId =
  GetMultipleAnswerPaginationParams & {
    requestId: string;
  };

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    newBulkAnswerRequest: mutationEndpointBuilder<
      undefined,
      NewRequestResponse,
      MultipleAnswerQueryParamsWithFile
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.copilot.bulkAnswer.NEW_REQUEST,
      body: (params) => {
        const formData = new FormData();
        formData.append("libraryId", params.libraryId.toString());
        formData.append("sheetName", params.sheetName);
        formData.append("column", params.column);
        formData.append("startingRow", params.startingRow.toString());
        formData.append("prospect", params.prospect);
        if (params.instruction) {
          formData.append("instruction", params.instruction);
        }
        formData.append("file", params.file);
        return formData;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["DashboardQuestionnaire"],
    }),
    getRequest: queryEndpointBuilder<
      PaginationMeta,
      GetMultipleAnswerData,
      GetMultipleAnswerParamsWithQueryId
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ requestId, offset }) =>
        `${APIEndpoints.copilot.bulkAnswer.GET_REQUEST(requestId)}?offset=${offset}`,
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: (_, __, { requestId }) => [
        { type: "BulkAnswer", id: requestId },
      ],
      onCacheEntryAdded: ({ requestId }) => {
        GeneralSocket.socketSend({
          type: "subscribe",
          topic: {
            type: "bulk-answer-request",
            key: requestId,
          },
        });
        GeneralSocket.socketSend({
          type: "subscribe",
          topic: {
            type: "bulk-answer-status",
            key: requestId,
          },
        });
      },
    }),
    promoteRequest: mutationEndpointBuilder<
      undefined,
      PromoteRequestResponse,
      PromoteRequestParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.copilot.bulkAnswer.PROMOTE_REQUEST,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: (_, __, params) => [
        { type: "BulkAnswer", id: params.aiCompletionRequestId },
        "DashboardQuestionnaire",
      ],
    }),
  }),
});

export const {
  useNewBulkAnswerRequestMutation,
  useGetRequestQuery,
  usePromoteRequestMutation,
  util: bulkAnswersUtil,
} = extendedApi;

export const bulkAnswersApi = extendedApi;
